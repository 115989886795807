













































































































































































































































































































































































































































































































































































































































import { Vue as VueDecor, Component, Ref, Watch } from 'vue-property-decorator'
import CampoDeItemDeVenda from '@/components/venda/CampoDeItemDeVenda.vue'
import CardDeProduto from '@/components/produto/CardDeProduto.vue'
import DialogoDeRetirarProduto from './DialogoDeRetirarProduto.vue'
import DialogoDeSelecionarVariante from '@/components/produto/DialogoDeSelecionarVariante.vue'
import {
	Venda,
	FechamentoDeCaixa,
	ItemDaVenda,
	TurnoDeVenda,
	ProdutoFilho,
	Loja,
	Desconto,
	FormProdutoSimples,
	ProdutoComVariantes,
	FormProdutoSimplesFilho,
	Categoria,
	FiltroDeBuscaDeProdutos,
	DetalhesDoPagamento,
	ItemDaComanda,
	Cliente,
	Comanda,
	DescontoProgressivo,
} from '@/models'
import CardDeCompras from './CardDeCompras.vue'
import DialogoDePagamento from '@/components/venda/DialogoDePagamento.vue'
import DialogoDeFechamentoDeCaixa from '@/components/venda/DialogoDeFechamentoDeCaixa.vue'
import DialogoDeDevolucaoDeVenda from '@/components/venda/DialogoDeDevolucaoDeVenda.vue'
import CardDeAberturaDeCaixa from './CardDeAberturaDeCaixa.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import { isIostec, isVestcasa, isSantaines, isTexcotton, isMarcatto, isFormitz } from '@/shareds/utils'
import {
	ComandaUseCase,
	EditarUmaVendaUseCase,
	EmailUseCase,
	FecharCaixaUseCase,
	FinalizarVendaUseCase,
	FindCategoriaUseCase,
	FindClienteUseCase,
	FindConfiguracaoDaContaUseCase,
	FindLojaUseCase,
	FindModalidadeDeVendaUseCase,
	FindProdutoUseCase,
} from '@/usecases'
import type { PagamentoServiceAdapter } from '@/usecases'
import type { VendaServiceAdapter } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DialogoDeMovimentacoes from '@/components/venda/DialogoDeMovimentacoes.vue'
import DialogoDeReimpressao from '@/components/venda/DialogoDeReimpressao.vue'
import DialogoDeAutenticacao from './DialogoDeAutenticacao.vue'
import { criarVendaDoPdv } from '@/shareds/venda-shareds'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import AplicarTabelaDePrecoUseCase from '@/usecases/tabela-de-precos/AplicarTabelaDePrecoUseCase'
import { converterProdutoParaProdutoSimples, obterQuantidadePesadaNaBalanca } from '@/shareds/produto-shareds'
import { imprimirFechamentoDeCaixa } from '@/shareds/fechamentos/impressao-do-fechamento-de-caixa'
import DialogoDeEdicaoDePagamento from '@/components/venda/DialogoDeEdicaoDePagamento.vue'
import DialogoDeConsultaDeProduto from './DialogoDeConsultaDeProduto.vue'
import DialogoDeVendaDesatualizada from './DialogoDeVendaDesatualizada.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import DialogoDeDescontoEmGrupo from './DialogoDeDescontoEmGrupo.vue'
import { ReimpressaoModule } from '@/store/vuex/reimpressao/ReimpressaoStore'
import DialogoDeInformarVendedor from './DialogoDeInformarVendedor.vue'
import DialogoDeInformarEmail from './DialogoDeInformarEmail.vue'
import DialogoDeReabrirVenda from './DialogoDeReabrirVenda.vue'
import axios, { CancelTokenSource } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { Inject } from 'inversify-props'
import Axios from 'axios'
import AlterarQuantidadeLimiteDoProduto from '@/components/ui/AlterarQuantidadeLimiteDoProduto.vue'
import DialogoDeImportarComandas from '../comandas/DialogoDeImportarComandas.vue'
import Vue from "vue"
import VueQuagga from "vue-quaggajs"
import AplicarPrecoDeCustoUseCase from '@/usecases/tabela-de-precos/AplicarPrecoDeCustoUseCase'
import DialogoDeImportarPreVenda from './DialogoDeImportarPreVenda.vue'
import DialogoDeInformarModalidade from './DialogoDeInformarModalidade.vue'
import DialogoDeRetornoDeCrediario from './DialogoDeRetornoDeCrediario.vue'
import DialogoDeRetornoDemonstracaoConsignado from './DialogoDeRetornoDemonstracaoConsignado.vue'
import DialogoDeImpressaoDeRomaneio from '@/components/venda/DialogoDeImpressaoDeRomaneio.vue'
import DialogoDeTurnoEncerrado from '@/views/application/fechamentosdecaixa/DialogoDeTurnoEncerrado.vue'
import CampoDeTelefone from '@/components/ui/CampoDeTelefone.vue'
import { obrigatorio } from '@/shareds/regras-de-form'

Vue.use(VueQuagga);

const ULTIMA_VENDA_COM_ERRO = 'ULTIMA_VENDA_COM_ERRO'

@Component({
	components: {
		Confirmacao,
		CampoDeItemDeVenda,
		CampoDeTelefone,
		CardDeProduto,
		DialogoDeSelecionarVariante,
		DialogoDePagamento,
		CardDeCompras,
		DialogoDeConsultaDeProduto,
		CardDeAberturaDeCaixa,
		DialogoDeFechamentoDeCaixa,
		DialogoDeMovimentacoes,
		DialogoDeDevolucaoDeVenda,
		DialogoDeEdicaoDePagamento,
		DialogoDeReimpressao,
		DialogoDeDescontoEmGrupo,
		DialogoDeInformarVendedor,
		DialogoDeInformarEmail,
		DialogoDeVendaDesatualizada,
		DialogoDeReabrirVenda,
		DialogoDeRetirarProduto,
		DialogoDeAutenticacao,
		AlterarQuantidadeLimiteDoProduto,
		DialogoDeImportarComandas,
		DialogoDeImportarPreVenda,
		DialogoDeInformarModalidade,
		DialogoDeRetornoDeCrediario,
		DialogoDeRetornoDemonstracaoConsignado,
		DialogoDeImpressaoDeRomaneio,
		DialogoDeTurnoEncerrado,
	},
})
export default class TelaDeCaixa extends VueDecor {
	@Ref() readonly selecaoDeVariante!: DialogoDeSelecionarVariante
	@Ref() readonly campoDeBusca!: CampoDeItemDeVenda
	@Ref() readonly botaoDeVerPagamentos!: { $el: HTMLButtonElement }
	@Ref() readonly cardDeCompras!: CardDeCompras
	@Ref() campoDeConsultaDePreco!: DialogoDeConsultaDeProduto
	@Ref() dialogoDeDesconto!: DialogoDeDescontoEmGrupo
	@Ref() dialogoDeInformarVendedor!: DialogoDeInformarVendedor
	@Ref() dialogoDeInformarEmail!: DialogoDeInformarEmail
	@Ref() dialogoDeReabrirVenda!: DialogoDeReabrirVenda
	@Ref() dialogoDeRetirarProduto!: DialogoDeRetirarProduto
	@Ref() dialogoDeAutenticacao!: DialogoDeAutenticacao
	@Ref() alterarQuantidadeLimiteDoProduto!: AlterarQuantidadeLimiteDoProduto
	@Ref() dialogoDeImportarPreVenda!: DialogoDeImportarPreVenda
	@Ref() dialogoDeInformarModalidade!: DialogoDeInformarModalidade
	@Ref() dialogoDeRetornoDemonstracaoConsignado!: DialogoDeRetornoDemonstracaoConsignado
	@Ref() confirmacaoDeTrocaVendedorItens!: Confirmacao
	@Ref() dialogoDeImpressaoDeRomaneio!: DialogoDeImpressaoDeRomaneio
	@Ref() dialogoDeTurnoEncerrado!: DialogoDeTurnoEncerrado

	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	mostraTelaDeImpressaoDeRomaneio = JSON.parse(localStorage.getItem('TELA_DE_IMPRESSAO_DE_ROMANEIO') || 'false');

	version = process.env.PACKAGE_VERSION || ''
	findProdutoUseCase = new FindProdutoUseCase()
	findCategoriaUseCase = new FindCategoriaUseCase()
	vestcasa = isVestcasa
	iostec = isIostec
	santaines = isSantaines
	texcotton = isTexcotton
	marcatto = isMarcatto
	formitz = isFormitz
	loja: Loja | null = null
	fecharCaixaUseCase = new FecharCaixaUseCase()
	findLojaUseCase = new FindLojaUseCase()
	comandaUseCase = new ComandaUseCase()
	zIndex = 2
	requestsPendentes = 0
	mostrarContagem = false
	pedindoCliente = false
	produtos: FormProdutoSimples[] = []
	buscandoCliente = false
	carregandoProdutos = true
	mostrarPagamento = false
	podeFecharComanda = false
	mostraDialogoDeImportarPreVenda = false
	mostraDialogoDeRetornoDeCrediario = false
	busca: FiltroDeBuscaDeProdutos = {
		tipos: ['Padrão', 'Com Variantes', 'Composto'],
		produtoDesativado: false,
	}
	categorias: Categoria[] = []
	cancelToken: CancelTokenSource | null = null
	parametroIdentificador: string | null = null
	devolverTudo = false

	mostrarDialogoSelecaoProduto = false
	mostrarDialogoLeituraDeCodigoBarras = false
	selecionandoProduto = false
	permissaoConcedida: boolean | undefined = false
	mostraDialogoDeImportarComanda = false
	itensComanda: ItemDaComanda | null = null
	break: NodeJS.Timeout | null = null
	fluxo = 'Entrada'
	findClienteUseCase = new FindClienteUseCase()
	findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()
	vendaUseCase = new EditarUmaVendaUseCase()

	readerSize = {
		width: 860,
		height: 480,
	}

	showCamera = false
	valorLido: string | null = null
	buscandoProduto = false

	mostrarDialogoParaInformarEmail = false
	mostrarInformacoesAdicionais = false
	itemInformacaoAdicional: ItemDaVenda | null = null
	mostraDialogoTelefone = false
	telefoneParaEnvio = ''
	obrigatorio = obrigatorio
	
	@Inject('PagamentoServiceAdapter')
	private pagamentoService!: PagamentoServiceAdapter

	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()

	set venda(venda: Venda | null) {
		VendaModule.setVendaAtual(venda)
	}

	get venda() {
		return VendaModule.vendaAtual
	}

	get temVendaComPagamentos() {
		return VendaModule.temVendaComPagamentos
	}

	get buscaUnica() {
		return this.vestcasa || this.santaines || this.texcotton || this.marcatto || this.formitz
	}

	set vendas(vendas: Venda[]) {
		VendaModule.setVendasDoTurno(vendas)
	}

	get vendas() {
		return VendaModule.vendasDoTurno
	}

	get vendaComErro() {
		if (!this.venda) return false

		const ultimaVendaComErro: Venda | null  = localStorage[ULTIMA_VENDA_COM_ERRO] 
			? JSON.parse(localStorage[ULTIMA_VENDA_COM_ERRO]) 
			: null

		return ultimaVendaComErro && ultimaVendaComErro.identificador === this.venda?.identificador
	}

	set turnoDeVenda(turno: TurnoDeVenda | null) {
		VendaModule.setTurnoDeVenda(turno)
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	get lojaDaVenda() {
		return VendaModule.lojaDaVenda
	}

	get podeConsultarProduto() {
		return UserLoginStore.permiteRegraDeNegocio('dna-do-produto')
	}

	get podeFazerVendaBrinde() {
		return UserLoginStore.permiteRegraDeNegocio('pode-fazer-venda-brinde')
	}

	get podeFazerVendaConsignada() {
		return UserLoginStore.permiteRegraDeNegocio('pode-fazer-venda-consignada')
	}

	get podeFazerVendaDemonstracao() {
		return UserLoginStore.permiteRegraDeNegocio('pode-fazer-venda-demonstracao')
	}

	get valorDoDesconto(){
		if(!this.descontoEmSerie) return ''
		return this.descontoEmSerie.valor
	}

	get badgeList() {

		const objBadge = [{
			color: "",
			content: "",
			size: 0,
		}]
	
		if(this.valorDoDesconto) {
			objBadge.push({
				color: "red",
				content: `${this.valorDoDesconto + '%'}`,
				size: this.venda && (this.venda.isConsignado || this.venda.isDemonstracao) ? -80 : 10,
			})
		}

		if(this.venda) {

			if (this.venda.isConsignado) {
				objBadge.push({
					color: "red",
					content: "CONSIGNADO",
					size: 10,
				})
			}

			if (this.venda.isBrinde) {
				objBadge.push({
					color: "green",
					content: "BRINDE",
					size: 10,
				})
			}

			if (this.venda.isDemonstracao) {
				objBadge.push({
					color: "grey",
					content: "DEMONSTRAÇÃO",
					size: 10,
				})
			}
		}
		return objBadge
	}

	get pdvAgrupaOsItensDaVenda(): boolean {
		return this.turnoDeVenda?.pontoDeVenda.agrupaProdutosNaVenda || false
	}

	async abrirDialogoDeRemoverItens(){
		if (!await VendaModule.autenticarAcao({ regra: 'pode-excluir-item-de-venda' })) return
		this.dialogoDeRetirarProduto.mostrar()
	}
	
	async iniciarNovaVenda(novaVendaReaberta) {
		if (VendaModule.turnoDeVenda){
			const identificador = await this.vendaService.gerarIdentificadorDeVenda(VendaModule.turnoDeVenda.pontoDeVenda.id)

			VendaModule.iniciarNovaVenda({ identificador: identificador, novaVendaReaberta: novaVendaReaberta })

			await this.alternaModalidadeDeVenda()
		}
	}

	async created() {
		const estadoPersistido = JSON.parse(localStorage.getItem('TELA_DE_IMPRESSAO_DE_ROMANEIO') || 'false');

		if (estadoPersistido) {
			this.dialogoDeImpressaoDeRomaneio.mostrar();
		}
		document.addEventListener('keydown', this.atalhoDeSelecaoDeProduto)
		document.addEventListener('keydown', this.atalhoDeDescontoEmGrupo)
		if (VendaModule.turnoDeVenda && !VendaModule.vendaAtual) {
			this.iniciarNovaVenda(false)
			this.podeFecharComanda = await VendaModule.autenticarAcao({ regra: 'pode-excluir-item-de-venda' }) ? true : false
		}
		this.buscarCategorias()
		this.validarParametroDevolucao()
	}

	async buscarCategorias() {		
		const page = await this.findCategoriaUseCase.findAll({ size: -1 }, {})
		this.categorias = page.content
	}

	async buscarProdutos() {
		this.carregandoProdutos = true

		if (this.cancelToken) this.cancelToken.cancel()
		this.cancelToken = axios.CancelToken.source()

		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}

		try {
			const produtosPage = await this.findProdutoUseCase.find(this.busca, axiosConfig)
			this.produtos = produtosPage.content
			this.carregandoProdutos = false
		}
		catch(error) {
			if (Axios.isCancel(error))
				this.carregandoProdutos = false
		}
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeSelecaoDeProduto)
	}

	mounted() {
		this.focarBusca()
		VendaModule.setDialogDeAutenticacao(this.dialogoDeAutenticacao)
		this.validaStatusDoPDV();
	}

	async vendaConsignada() {
		if(!this.venda || !VendaModule.turnoDeVenda) return 

		if (this.venda.isBrinde || this.venda.isDemonstracao) {
			this.iniciarNovaVenda(false)
		}

		if(this.venda.isConsignado) {
			this.iniciarNovaVenda(false)
			return 
		}
		this.venda.desconto = null
		this.venda.isConsignado = true
		if(this.venda.cpfDoCliente){
			this.validaBloqueiosDePagamento(this.venda.isConsignado, 'Consignado')
		}
		await this.alternaModalidadeDeVenda()
	}

	async vendaBrinde() {
		if(!this.venda || !VendaModule.turnoDeVenda) return 

		if(this.venda.isConsignado || this.venda.isDemonstracao) {
			this.iniciarNovaVenda(false)
			this.venda.isBrinde = true
			return
		}

		if(this.venda.isBrinde) {
			this.venda.isBrinde = false
			this.venda.itens.forEach(item => item.isBrinde = false)
		} else {
			this.venda.isBrinde = true
			this.venda.itens.forEach(item => item.isBrinde = true)
			if(this.venda.cpfDoCliente){
				this.validaBloqueiosDePagamento(this.venda.isBrinde, 'Brinde')
			}
		}
	}

	async vendaDemonstracao() {
		if(!this.venda || !VendaModule.turnoDeVenda) return 
		if(this.venda.vendaOrigem != null || this.venda.tipoDeTransacao==='Devolução'){
			AlertModule.setError("Venda reaberta não gera demonstração.")
			this.venda.isDemonstracao = false
			return
		}

		if(this.venda.isConsignado || this.venda.isBrinde) {
			this.iniciarNovaVenda(false)
			this.venda.isDemonstracao = true
			return
		}

		if(this.venda.isDemonstracao) {
			this.venda.isDemonstracao = false
		} else {
			this.venda.desconto = null
			this.venda.isDemonstracao = true
			if(this.venda.cpfDoCliente){
				this.validaBloqueiosDePagamento(this.venda.isDemonstracao, 'Demonstração')
			}
		}
		await this.alternaModalidadeDeVenda()
	}

	async validarParametroDevolucao() {
		if (this.$route.query.identificador !== undefined) {
			this.parametroIdentificador = this.$route.query.identificador.toString()
		} else {
			this.parametroIdentificador = ''
		}
	}

	excluirPagamentos(){
		const { venda } = this
		if(!venda) return
		this.venda = {
			...venda,
			pagamentos: [],
		}
	}

	atalhoDeSelecaoDeProduto(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 's') {
			this.focarBusca()
		}
	}

	async abriuTurnoDeVenda(turnoDeVenda: TurnoDeVenda) {
		this.turnoDeVenda = turnoDeVenda
		this.vendas = []

		this.venda = criarVendaDoPdv(this.turnoDeVenda.pontoDeVenda)

		this.venda.identificador = await this.vendaService.gerarIdentificadorDeVenda(this.turnoDeVenda.pontoDeVenda.id)

		const lojaDaVenda = await this.findLojaUseCase.findLojaById(
			this.turnoDeVenda.pontoDeVenda.loja.id,
		)
		VendaModule.setLojaDaVenda(lojaDaVenda)
		if (VendaModule.lembraDePedirOCliente) {
			this.pedindoCliente = VendaModule.lembraDePedirOCliente
		} else {
			this.focarBusca()
		}

		await this.iniciarNovaVenda(false)

		if (this.turnoDeVenda.pontoDeVenda.exigeVendedor && (!this.venda.vendedor || this.venda.vendedor.length === 0)) {
			this.abrirDialogoDeVendedor(true)
		}

		let modalidade = await this.alternaModalidadeDeVenda()

		if (this.turnoDeVenda.pontoDeVenda.exigeModalidade && modalidade) {
			this.abrirDialogoDeModalidade(true)
		}
		VendaModule.setEtapaVenda({estado: 'SEM_PROCESSO', vendas: []})
	}

	aplicarDescontoNaVenda(desconto: number) {
		this.descontoEmSerie = desconto
			? {
				isPercentual: true,
				valor: desconto,
			}
			: null

		this.focarBusca()
	}


	inserirTaxaDeServicoNaVenda(taxa: number) {
		if (!this.venda) return;

		const valorDaTaxaDeServico = this.venda.itens.map(item => Number(item.preco) * Number(item.quantidade))
			.reduce((valor, total) => Number(valor) + Number(total), 0) * Number(taxa / 100);

		this.venda.taxaDeServico = valorDaTaxaDeServico;
	}


	@Watch('turnoDeVenda', { immediate: true })
	async onChangeTurnoDeVenda() {
		if (!this.turnoDeVenda) return

		this.findLojaUseCase.findLojaById(this.turnoDeVenda.pontoDeVenda.loja.id)
			.then(loja => this.loja = loja)

		if (!VendaModule.vendaAtual) return
		if (!VendaModule.turnoDeVenda) return
		if (!VendaModule.vendaAtual.vendedor && VendaModule.turnoDeVenda.pontoDeVenda.exigeVendedor){
			this.abrirDialogoDeVendedor(true)
		}

		if (!VendaModule.vendaAtual.modalidadeDeVenda && VendaModule.turnoDeVenda.pontoDeVenda.exigeModalidade){
			this.abrirDialogoDeModalidade(true)
		}
	}

	@Watch('venda', { immediate: true, deep: true })
	onChangeVenda(venda) {
		if (venda.itens.length === 0) {
			VendaModule.setEtapaVenda({
				vendas: [],
				estado: 'SEM_PROCESSO',
			})
		}
		VendaModule.setVendaAtual(venda)
	}

	@Watch('vendaDevolucaoConsignada' , { immediate: true, deep: true })
	onChangeVendaDevolucaoConsignada(vendaDevolucaoConsignada) {
		VendaModule.setVendaDevolucaoConsignada(vendaDevolucaoConsignada)
	}

	@Watch('vendaDevolucaoDemonstracao' , { immediate: true, deep: true })
	onChangeVendaDevolucaoDemonstracao(vendaDevolucaoDemonstracao) {
		VendaModule.setVendaDevolucaoDemonstracao(vendaDevolucaoDemonstracao)
	}

	get descontoEmSerie() {
		return VendaModule.descontoEmSerie
	}

	set descontoEmSerie(desconto: Desconto | null) {
		VendaModule.setDescontoEmSerie(desconto)
	}

	async abrirDialogoDesconto(){
		if (!await VendaModule.autenticarAcao({ regra: 'pode-conceder-desconto' })) return
		this.dialogoDeDesconto.abrirDialogo(this.descontoEmSerie)
	}

	async iniciarPagamentos() {
		//logica para definir desconto progressivo
		if (this.venda && !(this.venda.isDemonstracao || this.venda.isConsignado) && this.venda.tipoDeTransacao === 'Venda' && this.venda.tipoNota === 'Saída') {
			let estacoes = [] as any[]
			
			this.venda.itens.forEach(itemDaVendaAtual => {
				const estacaoDoItem = (itemDaVendaAtual.produto.estacao && typeof itemDaVendaAtual.produto.estacao === 'object' ? itemDaVendaAtual.produto.estacao.id : itemDaVendaAtual.produto.estacao)
				if (estacaoDoItem && !estacoes.includes(estacaoDoItem)) {
					estacoes.push(estacaoDoItem)
				}
			})

			if (this.lojaDaVenda) {
				const estacoesConfiguradas = await this.vendaService.findEstacoesConfiguradasPorLoja(this.lojaDaVenda.id) as any

				const params = {
					lojaId: this.lojaDaVenda.id,
					estacoes: estacoesConfiguradas,
				}

				if (estacoesConfiguradas.length > 0) {
					const descontosProgressivos = await this.vendaService.findDescontosProgressivosDaLoja(params)
					
					if (this.lojaDaVenda && this.venda && descontosProgressivos.length > 0) {
						const itensComEstacao = this.venda.itens.filter(i =>
							estacoesConfiguradas.includes((i.produto.estacao && typeof i.produto.estacao === 'object' ? i.produto.estacao.id : i.produto.estacao))
						)

						const descontoProgressivoDaColecaoAtual = descontosProgressivos.filter(descontoProgressivo => itensComEstacao.length === descontoProgressivo.quantidade)
						if (descontoProgressivoDaColecaoAtual.length > 0) {
							let quantidadeDeItensComDesconto = descontoProgressivoDaColecaoAtual[0].quantidade
							this.venda.itens.forEach(itemDaVendaAtual => {
								if (estacoesConfiguradas.includes(itemDaVendaAtual.produto.estacao && typeof itemDaVendaAtual.produto.estacao === 'object' ? itemDaVendaAtual.produto.estacao.id : itemDaVendaAtual.produto.estacao)
									&& quantidadeDeItensComDesconto > 0
									&& !itemDaVendaAtual.possuiDescontoPorTabela
								){
									itemDaVendaAtual.desconto.isPercentual = true
									itemDaVendaAtual.desconto.valor = descontoProgressivoDaColecaoAtual[0].percetualDesconto

									quantidadeDeItensComDesconto--
								}
							})
						} else {
							let descontoProgressivoSelecionado = {
								quantidade: 0
							} as DescontoProgressivo
							descontosProgressivos.forEach(decontoProgressivo => {
								if (decontoProgressivo.quantidade > descontoProgressivoSelecionado.quantidade) {
									descontoProgressivoSelecionado = decontoProgressivo
								}
							})

							if (descontoProgressivoSelecionado.quantidade > 0) {
								this.venda.itens.forEach(itemDaVendaAtual => {
									if (estacoesConfiguradas.includes(itemDaVendaAtual.produto.estacao && typeof itemDaVendaAtual.produto.estacao === 'object' ? itemDaVendaAtual.produto.estacao.id : itemDaVendaAtual.produto.estacao)
										&& !itemDaVendaAtual.possuiDescontoPorTabela
									){
										itemDaVendaAtual.desconto.isPercentual = true
										itemDaVendaAtual.desconto.valor = descontoProgressivoSelecionado.percetualDesconto
									}
								})
							}
						}
					}
				}
			}
		}

		this.mostraTelaDePagamento = true
	}

	cancelarVendaAtual() {
		this.requestsPendentes = 0
	}

	async finalizarVenda(gerarCupom: boolean, emitirDanfe: boolean) {
		if (!this.venda) return
		if (!this.turnoDeVenda) return

		const existeItemValePresente = this.venda.itens.some(item => item.produto.isValePresente) ?? false
		let vendaFeita: Venda
		let clienteDaVenda: Cliente | null = null

		try {
			const pagamentos = this.venda.pagamentos.filter(pagamento => pagamento.detalhesDoPagamentoId !== null)

			const detalhesDosPagamentos: DetalhesDoPagamento[] = []
			
			pagamentos.forEach(async (pagamento) => {
				if (pagamento.detalhesDoPagamentoId) {
					const detalhesDoPagamento = await this.pagamentoService.buscarDetalhesDoPagamento(pagamento.detalhesDoPagamentoId)
					detalhesDosPagamentos.push(detalhesDoPagamento)
				}
			})

			let itensDevolucao

			if (this.venda.isConsignado) {
				itensDevolucao = this.vendaDevolucaoConsignada?.itens
			} else if (this.venda.isDemonstracao) {
				itensDevolucao = this.vendaDevolucaoDemonstracao?.itens
			}

			clienteDaVenda = this.venda.cliente

			this.venda.autenticandoParaLiberarVendaSemEstoque = UserLoginStore.autenticandoParaLiberarVendaSemEstoque
			vendaFeita = await FinalizarVendaUseCase({
				venda: this.venda,
				turnoDeVenda: this.turnoDeVenda,
				gerarCupom,
				detalhesDosPagamentos,
				itensDevolucao: itensDevolucao,
				emitirDanfe,
			}) || this.venda.autenticandoParaLiberarVendaSemEstoque

			this.alternaModalidadeDeVenda()

			const comandaJson = localStorage["COMANDA_IMPORTADA"]
			const comanda: Comanda | null = comandaJson ? JSON.parse(comandaJson) : null

			if(comanda) {
				const comandaSalvar: Comanda = {
					id: comanda.id,
					identificador: comanda.identificador,
					dataHoraDeAbertura: comanda.dataHoraDeAbertura,
					aberto: false,
					nome: comanda.nome,
					descricao: '',
					itemDaComanda: [],
					createdAt: comanda.createdAt,
					updatedAt: comanda.updatedAt,
					taxaDeServico: 0,
					aguardandoProcessamentoNoCaixa: false,
				}

				await this.comandaUseCase.save(comandaSalvar)
				localStorage.setItem("COMANDA_IMPORTADA", JSON.stringify(null))
			}
		} catch(error) {
			AlertModule.setError(error)
			localStorage.setItem(ULTIMA_VENDA_COM_ERRO, JSON.stringify(this.venda))
			throw error
		}

		this.mostraTelaDePagamento = false
		this.focarBusca()
		this.pedindoCliente = VendaModule.lembraDePedirOCliente

		if (this.turnoDeVenda.pontoDeVenda.enviarNotaPorEmail && gerarCupom) {
			this.enviarEmail(vendaFeita, clienteDaVenda)
		} 

		if(existeItemValePresente) {
			this.enviarValesPorEmail(vendaFeita, clienteDaVenda)
		}

		if(vendaFeita.tipoDeTransacao == 'Devolução'){
			return
		}

		if(this.turnoDeVenda.pontoDeVenda.autoEnviarWhatsapp) {
			this.enviarNotaPorWhatsApp()
		}

		this.dialogoDeImpressaoDeRomaneio.mostrar()
	}

	async enviarNotaPorWhatsApp(){
		const vendaLocal = JSON.parse(localStorage.getItem('ULTIMA_VENDA') || '');

		const lojaId = vendaLocal.pontoDeVenda.loja.id
		const possuiConfiguracao = await this.findLojaUseCase.possuiPluginWhatsConfigurado(lojaId)

		if (vendaLocal 
			&& possuiConfiguracao
			&& vendaLocal.cpfDoCliente
			&& !vendaLocal.possuiNotaNaoEletronica
			&& vendaLocal.tipoDeTransacao === 'Venda') {

			try{
				const cliente = await this.findClienteUseCase.findClienteByCpfOuCnpj(vendaLocal.cpfDoCliente)
				if(cliente.telefones.length > 0){
					await this.vendaUseCase.disparoDeMensagemVenda(vendaLocal.id)
					AlertModule.setSuccess('Mensagem enviada com sucesso.')
				} else {
					this.mostraDialogoTelefone = true
				}
			} catch(error: any){
				AlertModule.setError(error)
			}
		}
	}

	async enviarParaOTelefone() {
		const telefoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/;
		if (!this.telefoneParaEnvio || !telefoneRegex.test(this.telefoneParaEnvio.trim())) {
			AlertModule.setError('Informe um número de telefone válido no formato (XX) XXXXX-XXXX.');
			return;
		}
		const telefoneFormatado = String(this.telefoneParaEnvio).trim();
		const vendaLocal = JSON.parse(localStorage.getItem('ULTIMA_VENDA') || '');
		try {
			await this.vendaUseCase.disparoDeMensagemVenda(vendaLocal.id, telefoneFormatado);
			AlertModule.setSuccess('Mensagem enviada com sucesso.');
        
			this.mostraDialogoTelefone = false;
		} catch (error: any) {
			AlertModule.setError('Erro ao enviar a mensagem.');
		} finally {
			this.telefoneParaEnvio = ''; 
		}
	}

	setDevolverTudo(setar: boolean) {
		this.devolverTudo = setar
	}

	

	async enviarEmail(venda: Venda, cliente: Cliente | null) {
		try {
			if (cliente) {
				if (cliente.email) {
					await this.vendaService.enviarEmail(venda.id, cliente.email)
				} else {
					this.dialogoDeInformarEmail.mostrar(venda)
				}
			} else {
				this.dialogoDeInformarEmail.mostrar(venda)
			}
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	async enviarValesPorEmail(venda: Venda, cliente: Cliente | null) {
		try {
			if (cliente) {
				if (cliente.email) {
					await new EmailUseCase().enviarValesPorEmail(
						venda.id,
						cliente.email,
					)
				} else {
					this.dialogoDeInformarEmail.mostrar(venda)
				}
			} else {
				this.dialogoDeInformarEmail.mostrar(venda)
			}
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	incluirItemComandaNaVenda(item: ItemDaVenda) {
		if (!this.venda) return
		if (!this.pdvAgrupaOsItensDaVenda) {
			this.venda = {
				...this.venda,
				itens: [
					...this.venda.itens,
					item,
				],
			}
			return
		}

		const indiceDoItemBipado = this.venda.itens.findIndex(
			( produto ) => produto.produto.id === item.produto.id && produto.idConjunto === null,
		)

		if (indiceDoItemBipado === -1) {
			this.venda.itens.unshift(item)
			return
		}
		const itemAnterior = this.venda.itens[indiceDoItemBipado]
		this.venda.itens.splice(indiceDoItemBipado, 1)

		const quantidadeTotalDoItem = itemAnterior.quantidade + item.quantidade

		this.venda.itens.unshift({
			...itemAnterior,
			quantidade: Number(quantidadeTotalDoItem.toFixed(3)),
		})

	}

	get existeItemValePresente() {
		return this.venda?.itens.some(item => item.produto.isValePresente) ?? false
	}

	async incluirItemDeVenda(item: ItemDaVenda) {
		if (!this.loja) return

		const tipoDeCliente = this.venda && this.venda.tipoDeCliente
			? this.venda.tipoDeCliente
			: null
		const itens = this.normalizaCasoSejaComposto(item)
		if (!this.venda) return

		try {
			++this.requestsPendentes

			if(item.produto.isValePresente && (this.venda.isDemonstracao || this.venda.isConsignado || this.venda.isBrinde)) {
				if(this.venda.isDemonstracao) {
					throw new Error('Vale-presente não pode ser vendido em: Demonstração')
				}
				if(this.venda.isConsignado) {
					throw new Error('Vale-presente não pode ser vendido em: Consignado')
				}
				if(this.venda.isBrinde) {
					throw new Error('Vale-presente não pode ser vendido em: Brinde')
				}
			}

			if (this.existeItemValePresente && !item.produto.isValePresente) {
				throw new Error('Itens que não são vale-presente não podem ser vendidos juntamente com vale-presente')
			} 

			if (!this.existeItemValePresente && item.produto.isValePresente && this.venda.itens.length > 0) {
				throw new Error('Vale-presente não pode ser vendido juntamente com itens que não são vale-presente')
			}

			if(this.descontoEmSerie && this.descontoEmSerie.valor > 0 && item.produto.isValePresente) {
				throw new Error('Não é possível aplicar desconto no vale-presente')
			}

			let itensComPrecoAplicado
			if (this.venda.isBrinde) {
				itensComPrecoAplicado = await AplicarPrecoDeCustoUseCase({
					loja: this.loja,
					itens,
				})
			} else {
				itensComPrecoAplicado = await AplicarTabelaDePrecoUseCase({
					loja: this.loja,
					itens,
					tipoDeCliente,
					validarLimiteDeProduto: true,
					vendaAtual: this.venda,
				})
			}

			if (!this.venda) return

			if (this.venda.itens.length === 0) {
				const identificador = await this.vendaService.gerarIdentificadorDeVenda(
					this.turnoDeVenda?.pontoDeVenda.id || '',
				)
				this.venda.identificador = identificador
				this.venda.id = uuidv4()
			}

			itensComPrecoAplicado.forEach(itemComPrecoAplicado => {
				if (!this.venda) return

				if (this.venda.vendedor && this.venda.vendedor.length > 0) {
					itemComPrecoAplicado.vendedor = this.venda.vendedor[0]
				}

				if (!this.pdvAgrupaOsItensDaVenda && !this.venda.isVendaReaberta && (
					((!this.venda.isConsignado) && !this.vendaDevolucaoConsignada) 
					||
					(!this.venda.isDemonstracao && !this.vendaDevolucaoDemonstracao)
				)) {
					this.venda = {
						...this.venda,
						itens: [
							...this.venda.itens,
							itemComPrecoAplicado,
						],
					}
					return
				}
				if(this.venda.isVendaReaberta && (
					((this.venda.isConsignado) && this.vendaDevolucaoConsignada) 
					||
					(this.venda.isDemonstracao && this.vendaDevolucaoDemonstracao)
				)) {
					this.adicionarItemNaDevolucaoConsigadaOuDemonstracao(itemComPrecoAplicado)
				} else {
					this.validarTroca(this.venda, itemComPrecoAplicado, item)

					const indiceDoItemBipado = this.venda.itens.findIndex(
						( itemVenda ) => itemVenda.produto.id === itemComPrecoAplicado.produto.id
							&& itemVenda.idConjunto === null 
							&& itemComPrecoAplicado.preco === itemVenda.preco
							&& itemVenda.desconto.isPercentual === itemComPrecoAplicado.desconto.isPercentual
							&& itemVenda.desconto.valor === itemComPrecoAplicado.desconto.valor 
							&& itemVenda.quantidade > 0)

					if (indiceDoItemBipado === -1) {
						if (this.venda.isBrinde) itemComPrecoAplicado.isBrinde = true
						this.venda.itens.unshift(itemComPrecoAplicado)
						return
					}
					const itemAnterior = this.venda.itens[indiceDoItemBipado]
					this.venda.itens.splice(indiceDoItemBipado, 1)

					const quantidadeTotalDoItem = itemAnterior.quantidade + item.quantidade

					if (this.venda.isBrinde) {
						itemAnterior.isBrinde = true
					}

					this.venda.itens.unshift({
						...itemAnterior,
						quantidade: Number(quantidadeTotalDoItem.toFixed(3)),
					})
				}
			})

		} catch (error: any) {
			AlertModule.setError(error)
			if(AlertModule.getErrorMsg === 'Produto tem limite, favor informar CPF'){
				this.cardDeCompras.pedirCliente()
			} else if (AlertModule.getErrorMsg === 'Cliente já esgotou limite de compras desse produto no dia atual') {
				this.podeAlterarQuantidadeDeProduto(item)
			}
		} finally {
			if((this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros')
			&& this.venda.itens.filter(item => item.quantidade > 0).length > 0){
				this.alternaModalidadeDeVenda()
			}
			--this.requestsPendentes
			VendaModule.setCarregandoItensDaVenda(false)
		}
		this.focarBusca()
	}

	validarTroca(venda: Venda, itemComPrecoAplicado, itemASerIncluso: ItemDaVenda) {
		/*separa itens que vão entrar e os itens que vão sair*/
		const itensDaDevolucao = venda.itens.filter(item => item.quantidade < 0)
		const itensDaVenda = venda.itens.filter(item => item.quantidade > 0)

		/*Lista para fazer a relação de produto com quantidade apenas dos itens devolvidos*/
		let produtosDaDevolucao: [
			{
				produtoId: string
				quantidade: number
			}
		] | undefined

		/*Percorre itens da devolução para agrupar quantidade por produtos na lista produtosDaDevolucao*/
		itensDaDevolucao.forEach(itemDevolucao => {
			if (produtosDaDevolucao) {
				const indexProdutoDevolucao = produtosDaDevolucao.findIndex(item => (itemDevolucao.produto.tipo === 'Variante' 
					? itemDevolucao.produto.idProdutoPai
					: itemDevolucao.produto.id) === item.produtoId)

				if (indexProdutoDevolucao === -1) {
					produtosDaDevolucao.push(
						{
							produtoId: itemDevolucao.produto.tipo === 'Variante'
								? itemDevolucao.produto.idProdutoPai
								: itemDevolucao.produto.id,
							quantidade: Math.abs(itemDevolucao.quantidade),
						},
					)
				} else {
					produtosDaDevolucao[indexProdutoDevolucao].quantidade = produtosDaDevolucao[indexProdutoDevolucao].quantidade + Math.abs(itemDevolucao.quantidade)
				}
			} else {
				produtosDaDevolucao = [
					{
						produtoId:  itemDevolucao.produto.tipo === 'Variante'
							? itemDevolucao.produto.idProdutoPai
							: itemDevolucao.produto.id,
						quantidade: Math.abs(itemDevolucao.quantidade),
					},
				]
			}
		})

		let totalmenteDevolvido = false
		if (produtosDaDevolucao) {
			/*Percorre itens da venda para verificar se itens da devolução já foram incluidos na venda*/
			itensDaVenda.forEach(itemVenda => {
				let totalDoItemNaVenda = itemVenda.quantidade
				let mesmosItensNaVenda
				if (itemVenda.produto.tipo === 'Variante') {
					mesmosItensNaVenda = venda.itens.filter(item => item.quantidade < 0 && 
						item.produto.tipo === 'Variante' && 
						itemVenda.produto.tipo === 'Variante' &&
						item.produto.idProdutoPai === itemVenda.produto.idProdutoPai)
					
					if (mesmosItensNaVenda && mesmosItensNaVenda.length > 0) {
						totalDoItemNaVenda = mesmosItensNaVenda.map(item => item.quantidade).reduce((total, valor) => total = total + valor)
					}
				}

				venda.itens.filter(item => item.quantidade < 0).forEach(item => {
					if (item.quantidade < 0) {
						if ((mesmosItensNaVenda && mesmosItensNaVenda.length === 1 && itemVenda.preco === item.preco && itemVenda.quantidade === Math.abs(item.quantidade)) ||
								(mesmosItensNaVenda && mesmosItensNaVenda.length > 1 && itemVenda.quantidade === Math.abs(totalDoItemNaVenda))) {
							item.isTotalmenteTrocado = true
						}
					}
				})
				if (produtosDaDevolucao) {
					const indexProduto = produtosDaDevolucao.findIndex(item => (itemVenda.produto.tipo === 'Variante'
						? itemVenda.produto.idProdutoPai
						: itemVenda.produto.id) === item.produtoId)

					if (indexProduto >= 0 && itemVenda.quantidade <= produtosDaDevolucao[indexProduto].quantidade) {
						produtosDaDevolucao[indexProduto].quantidade = produtosDaDevolucao[indexProduto].quantidade - itemVenda.quantidade
					}
				}
			})

			/*Verifica se todos os itens equivalentes a devolução já foram incluidos na venda, sendo que é necessário que as quantidade estejam zerados*/
			if (produtosDaDevolucao.map(produto => produto.quantidade).reduce((total, valor) => total = total + valor) === 0) {
				totalmenteDevolvido = true
			}

			/*Validação necessária para manter os preços dos itens da venda equivalente aos itens da devolução*/
			if (!totalmenteDevolvido && (venda.tipoDeTransacao === 'Devolução' || venda.tipoDeTransacao === 'Outros') && itemASerIncluso.quantidade > 0) {
				const itensIguais = venda.itens.filter(item => item.quantidade < 0 && !item.isTotalmenteTrocado &&
					(item.produto.tipo === 'Variante' ? item.produto.idProdutoPai : item.produto.id)
					===
					(itemComPrecoAplicado.produto.tipo === 'Variante' ? itemComPrecoAplicado.produto.idProdutoPai : itemComPrecoAplicado.produto.id))

				if (itensIguais.length > 0) {
					const itemIgual = itensIguais[0]

					itemComPrecoAplicado.percentualCashback = 0
					itemComPrecoAplicado.preco = itemIgual.preco
					itemComPrecoAplicado.desconto = itemIgual.desconto
					itemComPrecoAplicado.isItemReferenteATroca = true
				}
			}
		}
	}

	adicionarItemNaDevolucaoConsigadaOuDemonstracao(item: ItemDaVenda) {
		if (!this.venda || (!this.vendaDevolucaoConsignada && !this.vendaDevolucaoDemonstracao)) return;

		const indiceDoItemBipado = this.venda.itens.findIndex(
			(produto) => produto.produto.id === item.produto.id && produto.quantidade > 0,
		);

		/*const possuiNota = this.venda.notas.length > 0
		if (indiceDoItemBipado === -1 && possuiNota) {
			AlertModule.setError("Item não faz parte da demonstração origem")
		} else
		*/

		if (indiceDoItemBipado === -1 /*&& !possuiNota*/) {
			this.venda.itens.push(item);
		} else {
			const itemAnterior = this.venda.itens[indiceDoItemBipado];
			if (!this.devolverTudo && itemAnterior.isDaVendaReaberta && !itemAnterior.isCompra) {
				if(itemAnterior.quantidade > 0){
					this.venda.itens[indiceDoItemBipado].quantidade -= 1;
					if (this.venda.itens[indiceDoItemBipado].quantidade === 0) {
						this.venda.itens.splice(indiceDoItemBipado, 1);
					}
				} else{
					return AlertModule.setError("Item não pode ser menor que zero");
				}
				const quantidadeTotalDoItem = 0
				this.venda.itens.unshift({
					...itemAnterior,
					quantidade: Number(quantidadeTotalDoItem >= 0 
						? quantidadeTotalDoItem.toFixed(3) 
						: 0),
				})
			}
			else {
				const quantidadeDoItemBipado = this.venda.itens[indiceDoItemBipado].quantidade
				this.venda.itens[indiceDoItemBipado].quantidade = quantidadeDoItemBipado + item.quantidade
			}
		}
	}

	async podeAlterarQuantidadeDeProduto(item: ItemDaVenda) {
		if (!this.loja) return

		const tipoDeCliente =
			this.venda && this.venda.tipoDeCliente ? this.venda.tipoDeCliente : null

		const itens = this.normalizaCasoSejaComposto(item).map(itemMap => ({
			...itemMap,
			desconto: this.descontoEmSerie || itemMap.desconto,
		}))

		if (!this.venda) return

		try {

			const itensComPrecoAplicado = await AplicarTabelaDePrecoUseCase({
				loja: this.loja,
				itens,
				tipoDeCliente,
				validarLimiteDeProduto: false,
				vendaAtual: this.venda,
			})
			this.permissaoConcedida = await VendaModule.autenticarAcaoTelaDeCaixa({ regra:'pode-alterar-quantidade-de-produto' })
			if (!this.permissaoConcedida || !this.venda) return 
			this.alterarQuantidadeLimiteDoProduto.mostrar(itensComPrecoAplicado[0])

		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	normalizaCasoSejaComposto(item: ItemDaVenda) {
		let itens: ItemDaVenda[] = []
		
		if (item.produto.tipo === 'Composto') {
			const idConjunto = uuidv4()

			itens = item.produto.itens.map(itemDoProduto => ({
				...item,
				produto: itemDoProduto.produto,
				quantidade: item.quantidade * itemDoProduto.quantidade,
				idConjunto: idConjunto,
				desconto: itemDoProduto.desconto,
			}))
		} else {
			itens = [item]
		}

		return itens
	}

	incluirProdutoNaVenda(produto: ProdutoFilho | FormProdutoSimplesFilho) {
		if (!this.venda) return
		try {
			const itemDeVenda: ItemDaVenda = {
				id: '',
				produto: converterProdutoParaProdutoSimples(produto),
				desconto: { isPercentual: true, valor: 0 },
				preco: produto.preco || 0,
				descontoVarejo: produto.descontoVarejo,
				quantidade: 1,
				quantidadeLida: 0,
				precoDeCusto: 0,
				idConjunto: null,
				percentualCashback: 0,
				diasParaEfetivarCashback: 0,
				isBrinde: false,
				idItemNotaOrigem: null,
				chaveNotaOrigem: null,
				diasParaExpirarCashback: 0,
				possuiDescontoPorTabela: false,
				valorDescontoRateadoDoItem: 0,
				itemDeDevolucao: false,
				isTotalmenteTrocado: false,
				motivoCancelamento: null,
				isCompra: false,
				idItemOrigem: '',
				isDaVendaReaberta: false,
				vendedor: null,
				autenticadoParaLiberarProdutoSemEstoque: false,
				vendaOrigemDoItem: null,
				valorFreteRateado: 0,
				isItemReferenteATroca: false,
				isDeOutraLoja: false,
				percentualDescontoTabelaPreco: 0,
			}
			this.incluirItemDeVenda(itemDeVenda)
			this.focarBusca()
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	async selecionarProduto(produtoSimplificado: FormProdutoSimples) {
		try {
			this.selecionandoProduto = true
			this.mostrarDialogoSelecaoProduto = false
			if (produtoSimplificado.tipo === 'Com Variantes') {
				const produto = this.findProdutoUseCase.findProdutoById(produtoSimplificado.id)
				this.selecaoDeVariante.abrir(produto as Promise<ProdutoComVariantes>)
				this.mostrarDialogoSelecaoProduto = false
				return
			}

			const produto = await this.findProdutoUseCase.findProdutoById(produtoSimplificado.id)
			if (produto.tipo !== 'Com Variantes')
				this.incluirProdutoNaVenda(produto)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.selecionandoProduto = false
		}
	}

	focarBusca() {
		if(this.$route.query.voltarPraPedido === 'true') return
		setTimeout(() => {
			this.campoDeBusca.focus()
		})
	}

	get mostraTelaDeDevolucao() {
		if (this.$route.query.identificador !== undefined) {
			this.parametroIdentificador = this.$route.query.identificador.toString()
		} else {
			this.parametroIdentificador = ''
		}

		return this.$route.query.devolucao !== undefined && this.turnoDeVenda !== null ? true : false
	}

	get mostraTelaDePagamento() {
		return this.$route.query.payment !== undefined ? true : false
	}

	set mostraTelaDePagamento(mostra: boolean | null | undefined) {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { ...this.$route.query, payment: mostra ? null : undefined },
			})
			.catch()
	}

	@Watch('mostraTelaDePagamento')
	onChangeMostraTelaDePagamento(mostra: boolean) {
		if (mostra) {
			PluginModule.emit('abrirPagamentos', { venda: this.venda })
			return
		}
		if (!this.temVendaComPagamentos) return
		window.requestAnimationFrame(() => {
			this.botaoDeVerPagamentos.$el.focus()
		})
	}

	get mostraFechamento() {
		return this.$route.query.closing !== undefined
	}

	set mostraFechamento(mostra: boolean | null | undefined) {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { closing: mostra ? null : undefined },
			})
			.catch()
	}

	set vendaDevolucaoDemonstracao(venda: Venda | null) {
		VendaModule.setVendaAtual(venda)
	}

	get vendaDevolucaoDemonstracao() {
		return VendaModule.vendaDevolucaoDemonstracao
	}

	set vendaDevolucaoConsignada(venda: Venda | null) {
		VendaModule.setVendaAtual(venda)
	}

	get vendaDevolucaoConsignada() {
		return VendaModule.vendaDevolucaoConsignada
	}

	get mostraMovimentacao() {
		return this.$route.query['cash-handling'] !== undefined ? true : false
	}

	set mostraMovimentacao(mostra: boolean | null | undefined) {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { ['cash-handling']: mostra ? null : undefined },
			})
			.catch()
	}

	get mostrarReimpressao() {
		return this.$route.query['reimpressao'] !== undefined ? true : false
	}

	set mostrarReimpressao(mostra: boolean | null | undefined) {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { ['comprovantes']: mostra ? null : undefined },
			})
			.catch()
	}

	async encerrarTurnoDeVenda(fechamento: FechamentoDeCaixa) {
		if (!this.turnoDeVenda?.id) return
		try {
			await this.fecharCaixaUseCase.execute(this.turnoDeVenda.id, fechamento)

			if (this.turnoDeVenda.pontoDeVenda.imprimeComprovanteDoFechamento) {
				await imprimirFechamentoDeCaixa(this.turnoDeVenda, fechamento)
			}
			
			VendaModule.setInformacoesDoCliente(null)
			VendaModule.setTurnoDeVenda(null)
			VendaModule.setDescontoEmSerie(null)
			ReimpressaoModule.clearReimpressoes()
			this.mostraFechamento = false

			if(this.permiteAutoLogoffAoFecharCaixa) {
				UserLoginStore.setMostrarContagemRegressiva(true)
			}
			
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	get mostrarContador() {
		return this.permiteAutoLogoffAoFecharCaixa && this.mostrarContagem
	}

	get permiteAutoLogoffAoFecharCaixa() {
		return UserLoginStore.permiteRegraDeNegocio('auto-logoff-ao-fechar-caixa')
	}

	logout() {
		UserLoginStore.logout()
	}

	get temRequisicoesPendentes() {
		return !!this.requestsPendentes
	}

	get podeInformarVendedor() {
		return this.venda
	}

	get temAcoesDeMenu() {
		return !!this.turnoDeVenda || !!this.venda
	}

	async abrirDialogoDeVendedor(ignorarAutenticacao = false ) {
		if (!ignorarAutenticacao) {
			if (!await VendaModule.autenticarAcao({ regra: 'informar-vendedor' })) return
		}
		this.dialogoDeInformarVendedor.mostrar()
	}

	async abrirDialogoDeModalidade(ignorarAutenticacao = false) {
		if (!ignorarAutenticacao) {
			if (!await VendaModule.autenticarAcao({ regra: 'informar-modalidade' })) return
		}
		this.dialogoDeInformarModalidade.mostrar()
	}

	async abrirConsultaDePreco(){
		if (!await VendaModule.autenticarAcao({ regra: 'dna-do-produto' })) return
		this.campoDeConsultaDePreco.mostrar()
	}

	async abrirDialogoDeImportarComanda(){
		this.mostraDialogoDeImportarComanda = true
	}

	async abrirDialogoDeReabrirVenda(){
		if (!await VendaModule.autenticarAcao({ regra: 'reabrir-venda' })) return
		this.dialogoDeReabrirVenda.mostrar()
	}

	abrirDialogoDeImportarPreVenda() {
		this.mostraDialogoDeImportarPreVenda = true
	}

	abrirDialogoDeRetornoDeCrediario() {
		this.mostraDialogoDeRetornoDeCrediario = true
	}

	async abrirDialogoDeRetornoDemonstracaoConsignado(){
		if (!await VendaModule.autenticarAcao({ regra: 'reabrir-venda' })) return
		this.dialogoDeRetornoDemonstracaoConsignado.mostrar()
	}

	@Watch('busca', { deep: true })
	async onChangeBusca() {
		this.buscarProdutos()
	}

	async selecionarCategoria(categoria: Categoria, active: boolean) {
		this.busca.categorias = active ? [ categoria.id ] : undefined
		this.buscarProdutos()
	}

	async logIt(data: any) {
		this.showCamera = false
		this.buscandoProduto = true

		this.valorLido = data.codeResult.code

		if (this.valorLido && this.turnoDeVenda) {
			try {
				const produto = await this.findProdutoUseCase.findProdutoComEstoque(
					this.valorLido,
					this.turnoDeVenda.pontoDeVenda.loja.id,
				)

				const configuracaoDeConta = await this.findConfiguracaoDaContaUseCase.find()

				const quantidadeDeItens =
					produto.unidadeDeMedida !== 'KG' && produto.unidadeDeMedida != 'MT'
						? 1
						: obterQuantidadePesadaNaBalanca(
							'',
							configuracaoDeConta?.medida || 6,
							configuracaoDeConta?.eans || 7,
							configuracaoDeConta?.casasNumericas || 4,
						)
				const item: ItemDaVenda = {
					id: '',
					produto: converterProdutoParaProdutoSimples(produto),
					quantidade: quantidadeDeItens,
					quantidadeLida: 0,
					desconto: { isPercentual: true, valor: 0.0 },
					preco: produto.preco||0,
					precoDeCusto: 0,
					idConjunto: null,
					descontoVarejo: 0,
					percentualCashback: 0,
					diasParaEfetivarCashback: 0,
					isBrinde: false,
					idItemNotaOrigem: null,
					chaveNotaOrigem: null,
					diasParaExpirarCashback: 0,
					possuiDescontoPorTabela: false,
					valorDescontoRateadoDoItem: 0,
					itemDeDevolucao: false,
					isTotalmenteTrocado: false,
					motivoCancelamento: null,
					isCompra: false,
					idItemOrigem: '',
					isDaVendaReaberta: false,
					vendedor: null,
					autenticadoParaLiberarProdutoSemEstoque: false,
					vendaOrigemDoItem: null,
					valorFreteRateado: 0,
					isItemReferenteATroca: false,
					isDeOutraLoja: false,
					percentualDescontoTabelaPreco: 0,
				}

				this.incluirItemDeVenda(item)
			} catch (error: any) {
				const pluginAssumiuControle = await PluginModule.deixarPluginAssumirControle(
					'errorIncluirItemNaVenda',
					{
						valorLido: this.valorLido,
						lojaDaVenda: VendaModule.lojaDaVenda,
						status: error.response?.status,
					},
				)
				if(pluginAssumiuControle) return 
				AlertModule.setError(error)
			} finally {
				this.mostrarDialogoLeituraDeCodigoBarras = false
			}
		}
	}

	@Watch("venda.isBrinde")
	async onChangeIsBrinde() {
		if (!this.venda) return
		if (!this.loja) return

		let itensAtualizados
		try {
			if (this.venda.isBrinde) {
				itensAtualizados = await AplicarPrecoDeCustoUseCase({
					loja: this.loja,
					itens: this.venda.itens,
				})
			} else {
				itensAtualizados = await AplicarTabelaDePrecoUseCase({
					loja: this.loja,
					itens: this.venda.itens,
					tipoDeCliente: this.venda.tipoDeCliente,
					validarLimiteDeProduto: true,
					vendaAtual: this.venda,
				})
			}
		} catch (error: any) {
			AlertModule.setError(error)
			this.vendaBrinde()
			return
		}

		this.venda.itens = itensAtualizados
	}

	fecharDialogoDeDevolucao() {
		this.$router.push({name: 'Venda'})
		this.cardDeCompras.cpfOuCnpjDoCliente = this.venda?.cpfDoCliente as string | null
	}

	solicitarTrocaVendedorItens() {
		this.confirmacaoDeTrocaVendedorItens.mostrar()
	}

	confirmarTrocaVendedorItens() {
		if (!this.venda) return

		this.venda.itens.forEach(item => {
			if (!this.venda) return
			if (!this.venda.vendedor) return

			item.vendedor = this.venda.vendedor[0]
		})
	}

	get lojaIdLogada() {
		return VendaModule.lojaDaVenda?.id
	}

	async validaBloqueiosDePagamento(isTipo: boolean, tipoVenda: string){
		if(!this.venda) return
		if(isTipo){
			const tiposDePagamentoBloqueadosDoCliente = await this.findClienteUseCase.listarTiposDePagamentoBloqueadosCliente(this.venda.cliente?.id, this.turnoDeVenda?.pontoDeVenda.loja.id)
				
			const possuiBloqueioGeral = tiposDePagamentoBloqueadosDoCliente.some(tipo => 
				tipo.nome === tipoVenda,
			); if (possuiBloqueioGeral) { return AlertModule.setWarning(`${tipoVenda} não liberado para este cliente. Entrar em contato com sua supervisão.`) }
		}}

	atalhoDeDescontoEmGrupo(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 'z') {
			this.dialogoDeDesconto.abrirDialogo(this.descontoEmSerie)
		}
	}

	get ehUmaTroca() {
		if(!this.venda) return false
		return (this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros')
			&& this.venda.itens.filter(item => item.quantidade > 0).length > 0 
	}


	async alternaModalidadeDeVenda() {
		
		if(!this.loja) return
		if(!this.venda) return

		let params;
		if(this.venda.isConsignado || this.venda.isDemonstracao) {
			params = {
				isDevolucao:false,
				isDemonstracao:true,
				isVenda:false,
			}
		}else if(this.ehUmaTroca){
			params = {
				isDevolucao:false,
				isDemonstracao:false,
				isVenda:true,
			}
		}
		else if (this.venda.temDevolucao) {
			params = {
				isDevolucao:true,
				isDemonstracao:false,
				isVenda:false,
			}
		}else{
			params = {
				isDevolucao:false,
				isDemonstracao:false,
				isVenda:true,
			}
		}

		const modalidade = await this.findModalidadeDeVendaUseCase.findModalidadePadrao(this.loja.id, params)

		this.venda.modalidadeDeVenda = modalidade
		return modalidade;
	}

	validaStatusDoPDV() {
		const statusDoPDV = localStorage.getItem('TURNO_DE_VENDA_ENCERRADO')

    	if(statusDoPDV){
			this.dialogoDeTurnoEncerrado.mostrar()
			return
		}
    }

	abrirInfosAdicionais(item: ItemDaVenda) {
		this.mostrarInformacoesAdicionais = true
		this.itemInformacaoAdicional = item
	}

	fecharInfosAdicionais() {
		this.mostrarInformacoesAdicionais = false
		this.itemInformacaoAdicional = null
	}

	mostrarSelecionarProdutos() {
		this.mostrarDialogoSelecaoProduto = true
		this.buscarProdutos()
	}
}
