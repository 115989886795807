










































































































































































































































































import { ItemDaVendaComReferencias, Venda, VendaComReferencias } from '@/models'
import { TipoDeProcesso } from '@/models/anexo/AnexoDosProcessos'
import { formatarMoeda } from '@/shareds/formatadores'
import { imprimirRomaneio, gerarComprovanteDeDemonstracao80mm } from '@/shareds/pedidos/impressao-de-romaneio'
import { contarItensDaVenda, obterTotalDoItemComTaxas, obterTotalDosItensDaVenda, obterTrocoDaVenda, obterValorAPagar,
	imprimirConfissaoDeDivida, imprimirCarneDeCrediario } from '@/shareds/venda-shareds'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { EditarUmaVendaUseCase, FindClienteUseCase, FindLojaUseCase, FindVendaUseCase } from '@/usecases'
import { Vue, Component } from 'vue-property-decorator'
import CampoDeTelefone from '../ui/CampoDeTelefone.vue'
import { obrigatorio } from '@/shareds/regras-de-form'

@Component({
	components:{
		CampoDeTelefone,
	}})
export default class DialogoDeImpressaoDeRomaneio extends Vue {
	
	vendaLocal = JSON.parse(localStorage.getItem('ULTIMA_VENDA') || '');
	syncMostra = false
	itensDaVenda: ItemDaVendaComReferencias[] = []
	vendaComReferencias: VendaComReferencias | null = null
	vendas: Venda | null = null
	statusPlugin = false
	mostraDialogoTelefone = false
	novoTelefone = ''
	obrigatorio = obrigatorio

	findVendaUseCase = new FindVendaUseCase()
	findLojaUseCase = new FindLojaUseCase()
	vendaUseCase = new EditarUmaVendaUseCase()
	findClienteUseCase = new FindClienteUseCase()

	obterTotalDoItemComTaxas = obterTotalDoItemComTaxas
	formatarMoeda = formatarMoeda
	obterTotalDosItensDaVenda = obterTotalDosItensDaVenda
	contarItensDaVenda = contarItensDaVenda

	get restante() {
		return obterValorAPagar(this.vendaLocal)
	}

	get troco() {
		return obterTrocoDaVenda(this.vendaLocal)
	}

	get totalDaVendaOuDevolucao() {		
		return obterTotalDoItemComTaxas(this.vendaLocal) + (this.restante < 0 ? 0 : this.restante) - this.troco
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	async novaVenda() {
		if (this.turnoDeVenda) {
			try{
				this.syncMostra = false
				localStorage.setItem('TELA_DE_IMPRESSAO_DE_ROMANEIO', JSON.stringify(false));
			} catch(e) {
				AlertModule.setError(e)
			}
		}
	}

	created(){
		this.syncMostra = JSON.parse(localStorage.getItem('TELA_DE_IMPRESSAO_DE_ROMANEIO') || '');
		if(localStorage.getItem('TURNO_DE_VENDA_STORAGE') === null){
			localStorage.setItem('TELA_DE_IMPRESSAO_DE_ROMANEIO', JSON.stringify(false));
		}
		this.verificarPluginConfigurado()
	}

	mostrar() {
		this.syncMostra = true

		if(!this.vendaLocal || this.vendaLocal == '') return
		
		localStorage.setItem('TELA_DE_IMPRESSAO_DE_ROMANEIO', JSON.stringify(true));
		this.vendaLocal = JSON.parse(localStorage.getItem('ULTIMA_VENDA') || '');
	}

	async imprimirPdfA4Romaneio() {
		if (!this.vendaLocal) return

		this.vendaComReferencias = await this.findVendaUseCase.getVendaComReferencias(this.vendaLocal.id)
		const usuarioId = UserLoginStore.usuario?.id
		const qrData = `${this.extractDomain(window.location.href)}/anexo/${this.$route.fullPath.replace("/", "") as TipoDeProcesso}/${this.vendaLocal.id}/${usuarioId}`
		const produtosComReferencia = this.vendaComReferencias?.itens
		try {
			const vendaImpressao = await this.findVendaUseCase.get(this.vendaLocal.id)
			await imprimirRomaneio(vendaImpressao, qrData, produtosComReferencia)
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	async gerarComprovanteDeDemonstracao80mm() {
		if (!this.vendaLocal) return
		this.vendaComReferencias = await this.findVendaUseCase.getVendaComReferencias(this.vendaLocal.id)
		const usuarioId = UserLoginStore.usuario?.id
		const qrData = `${this.extractDomain(window.location.href)}/anexo/${this.$route.fullPath.replace("/", "") as TipoDeProcesso}/${this.vendaLocal.id}/${usuarioId}`
		const produtosComReferencia = this.vendaComReferencias?.itens
		try {
			const vendaImpressao = await this.findVendaUseCase.get(this.vendaLocal.id)
			await gerarComprovanteDeDemonstracao80mm(qrData, produtosComReferencia, vendaImpressao)
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	checkCrediario() {
		return this.vendaLocal.pagamentos.some(pagamento =>
			pagamento.tipoDePagamento.formaDePagamento === 'Crediário')
	}

	async imprimirConfissaoDeDivida(){
		try {
			await imprimirConfissaoDeDivida(this.vendaLocal.id)
		} catch (error: any) {
			AlertModule.setError(error)
		}	
	}

	async imprimirCarneDeCrediario(){
		try {
			await imprimirCarneDeCrediario(this.vendaLocal.id)
		} catch (error: any) {
			AlertModule.setError(error)
		}	
	}

	extractDomain(url) {
		let domain = url.replace(/^https?:\/\//, '')
		domain = domain.split('/')[0]
		domain = domain.split(':')[0]

		return domain;
	}

	async enviarNotaPorWhatsApp(){
		if (!this.vendaLocal.id) return
		try{
			const cliente = await this.findClienteUseCase.findClienteByCpfOuCnpj(this.vendaLocal.cpfDoCliente)
			if(cliente.telefones.length === 0){
				this.mostraDialogoTelefone = true
			}
			else{
				const enviarMensagem = await this.vendaUseCase.disparoDeMensagemVenda(this.vendaLocal.id)
				this.$emit('confirmado', enviarMensagem)
				AlertModule.setSuccess('Mensagem enviada com sucesso.')
			}
		} catch(error: any){
			AlertModule.setError(error)
		}
		finally{
			this.novoTelefone = ''
		}
	}

	async pluginMensagemConfigurado(){
		const lojaId = this.vendaLocal.pontoDeVenda.loja.id
		const possuiConfiguracao = await this.findLojaUseCase.possuiPluginWhatsConfigurado(lojaId)
		return possuiConfiguracao
	}

	async verificarPluginConfigurado() {
		this.statusPlugin = await this.pluginMensagemConfigurado()
	}

	async salvarTelefone() {
		const telefoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/;
		if (!this.novoTelefone || !telefoneRegex.test(this.novoTelefone.trim())) {
			AlertModule.setError('Informe um número de telefone válido no formato (XX) XXXXX-XXXX.');
			return;
		}
		const telefoneFormatado = String(this.novoTelefone).trim();

		try {
			const enviarMensagem = await this.vendaUseCase.disparoDeMensagemVenda(this.vendaLocal.id, telefoneFormatado);
			this.$emit('confirmado', enviarMensagem);
			AlertModule.setSuccess('Mensagem enviada com sucesso.');
        
			this.mostraDialogoTelefone = false;
		} catch (error: any) {
			AlertModule.setError('Erro ao enviar a mensagem.');
		} finally {
			this.novoTelefone = ''; 
		}
	}
}
