













































































































import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import type { Cliente, DadosParaReaberturaMultiplaDeVendas, FormProdutoSimplesFilho, ItemDaVenda, ItemParaControleDeQuantidade } from '@/models'
import { formatarMoeda } from '@/shareds/formatadores'
import { obterTotalDoItem } from '@/shareds/venda-shareds'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { FindVendaUseCase, ReabrirVendaUseCase } from '@/usecases'
import { Vue, Component, PropSync, Prop, Watch, Ref } from 'vue-property-decorator'

@Component({
	components: {
		BuscaDeProdutoDropdown,
		Confirmacao,
	},
})
export default class DialogoDeReabrirMultiplasVendas extends Vue {
	@PropSync('mostra', { type: Boolean, default: true }) syncMostra!: boolean
	@Prop() retornoMultiploDeDemonstracao!: boolean
	@Prop() cliente?: Cliente
	@Ref('confirmacao') confirmacao!: Confirmacao
	@Ref() campoDeBuscaDeProduto!: BuscaDeProdutoDropdown

	formatarMoeda = formatarMoeda
	obterTotalDoItem = obterTotalDoItem
	
	produto: FormProdutoSimplesFilho | null = null
	vendasComAQuantidadeDistribuida: DadosParaReaberturaMultiplaDeVendas[] = []
	itensDeTodasAsVenda: ItemDaVenda[] = []
	itensParaControleDeQuantidade: ItemParaControleDeQuantidade[] = []
	buscando = false
	painelAberto = [] as number[]
	retornandoDemonstracoes = false

	findVendaUseCase = new FindVendaUseCase()
	reabrirVendaUseCase = new ReabrirVendaUseCase()

	headers = [
		{ text: 'Produto', value: 'nome', sortable: false },
		{ text: 'Sku', value: 'sku', sortable: false },
		{ text: 'Ean', value: 'eans', sortable: false },
		{ text: 'Qtd', value: 'quantidade', sortable: false },
		{ text: 'Qtd. Lida', value: 'quantidadeLida', sortable: false },
		{ text: 'Preço', value: 'preco', sortable: false },
		{ value: 'actions', width: 'auto', sortable: false, align: 'left'},
	]

	async mounted() {
		setTimeout(() => {
			setTimeout(() => {
				this.campoDeBuscaDeProduto.focus()
			})
		})

		await this.buscarVendas()
	}

	isVendaCompletamentePreenchida(venda: DadosParaReaberturaMultiplaDeVendas) {
		return venda.itens.every((item) => (item.quantidade - item.quantidadeLida) === 0)
	}

	@Watch('produto', { immediate: true })
	inserirProdutos() {
		if(!this.produto) return

		const idx = this.itensParaControleDeQuantidade
			.findIndex(item => item.produto.id === this.produto?.id)

		let quantidadeLidaDoProduto = 0
		const quantidadeTotalDoItemEmTodasAsVendas = this.vendasComAQuantidadeDistribuida
			.flatMap(venda => venda.itens)
			.filter(item => item.produto.id === this.produto?.id)
			.reduce((total, item) => total + item.quantidade, 0)

		if (idx !== -1) {
			this.itensParaControleDeQuantidade[idx].quantidadeLida += 1
			quantidadeLidaDoProduto = this.itensParaControleDeQuantidade[idx].quantidadeLida
		} else {
			this.itensParaControleDeQuantidade.push({
				produto: this.produto,
				quantidadeLida: 1,
			})
		}

		if ((quantidadeTotalDoItemEmTodasAsVendas - quantidadeLidaDoProduto) < 0) {
			this.itensParaControleDeQuantidade[idx].quantidadeLida -= 1
			this.produto = null
			AlertModule.setError("Este produto não está associado a nenhuma outra venda")
		} else {
			const vendasComQuantidadesLidasLimpa: DadosParaReaberturaMultiplaDeVendas[] = this.vendaFormatadas.map(venda => (
				{
					...venda,
					itens: venda.itens.map(item => ({...item, quantidadeLida: 0})),
				}
			))

			this.vendasComAQuantidadeDistribuida = [
				...distribuirQuantidadesLidasNasVendas(JSON.parse(JSON.stringify(vendasComQuantidadesLidasLimpa)),
					this.itensParaControleDeQuantidade)]
			this.produto = null
		}
	}

	async buscarVendas() {
		if (!this.cliente || !VendaModule.lojaDaVenda) return

		try {
			this.buscando = true
			const vendas = await this.findVendaUseCase.buscarVendasParaRetornoMultiplo(
				this.cliente.cnpjOuCpf,
				VendaModule.lojaDaVenda.id,
				this.retornoMultiploDeDemonstracao,
			)
			this.painelAberto = vendas.map((_, index) => index)
			const todosOsItens = vendas.flatMap(venda =>
				venda.itens.map(item => ({
					...item,
				})),
			) as ItemDaVenda[]

			this.itensDeTodasAsVenda.push(...todosOsItens)
			this.vendasComAQuantidadeDistribuida = [...vendas]
			this.buscando = false
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	async finalizarProcessoDeRetornoMultiplo() {
		this.retornandoDemonstracoes = true

		if(!this.cliente) 
			return AlertModule.setError("Ocorreu um erro interno, contate o suporte")

		const vendasComQuantidade = this.vendasComAQuantidadeDistribuida.filter(venda => 
			venda.itens.some((item) => (item.quantidadeLida > 0) || item.isCompra))

		await this.reabrirVendaUseCase.reabrirVendasMultiplas(
			vendasComQuantidade,
			this.cliente,
			this.retornoMultiploDeDemonstracao,
		)

		this.$emit('finalizar')
		this.retornandoDemonstracoes = false
	}

	get vendaFormatadas() {
		return this.vendasComAQuantidadeDistribuida
			.map(venda => {
				const temProdutoLido = venda.itens.some(item => item.quantidadeLida > 0)
				const quantidadeLidaCompleta = venda.itens.every(item =>
					item.quantidadeLida > 0 ? item.quantidadeLida === item.quantidade : true,
				)
				const temProdutoComprado = venda.itens.some(item => item.isCompra)
				return {
					...venda,
					temProdutoLido,
					quantidadeLidaCompleta,
					temProdutoComprado,
					itens: venda.itens.map(item => ({
						...item,
						nome: item.produto.nome,
						quantidadeLida: item.quantidadeLida || 0,
						isCompra: item.isCompra || false,
					})),
				}
			})
			.sort((a, b) => {
				if (a.temProdutoLido && !a.quantidadeLidaCompleta && (!b.temProdutoLido || b.quantidadeLidaCompleta)) {
					return -1
				}
				if (b.temProdutoLido && !b.quantidadeLidaCompleta && (!a.temProdutoLido || a.quantidadeLidaCompleta)) {
					return 1
				}
				if (a.temProdutoLido && a.quantidadeLidaCompleta && (!b.temProdutoLido || !b.quantidadeLidaCompleta)) {
					return -1
				}
				if (b.temProdutoLido && b.quantidadeLidaCompleta && (!a.temProdutoLido || !a.quantidadeLidaCompleta)) {
					return 1
				}
				if (a.temProdutoComprado && !b.temProdutoComprado) {
					return -1
				}
				if (!a.temProdutoComprado && b.temProdutoComprado) {
					return 1
				}
				return 0
			})
	}

	alterouSwitch(venda, item) {
		const idxVenda = this.vendasComAQuantidadeDistribuida.findIndex(v => v.id === venda.id)

		if (idxVenda === -1) {
			console.error('Venda não encontrada na lista.')
			return
		}

		const itens = this.vendasComAQuantidadeDistribuida[idxVenda].itens
		const idxItem = itens.findIndex(i => i.id === item.id)

		if (idxItem === -1) {
			console.error('Item não encontrado na lista de itens.')
			return
		}
		
		itens.splice(idxItem, 1, item)
	}

	get podeConcluirProcesso() {
		return this.vendaFormatadas.filter(venda => 
			venda.temProdutoComprado || venda.itens.some((item) =>
				(item.quantidadeLida > 0))).length > 0
	}
}


function distribuirQuantidadesLidasNasVendas(
	listaDeVendas: DadosParaReaberturaMultiplaDeVendas[],
	listaDeProdutosLidos: ItemParaControleDeQuantidade[],
) {
	const produtosLidos = JSON.parse(JSON.stringify(listaDeProdutosLidos)) as ItemParaControleDeQuantidade[]
	const idProutosLidos = produtosLidos.map((pl) => pl.produto.id)
	const vendasComOsItensLidos = listaDeVendas.filter((venda) => {
		const itensDasVendas = venda.itens.map((item) => item.produto.id)
		return idProutosLidos.every((idItemVenda) =>
			itensDasVendas.includes(idItemVenda),
		)
	})

	let vendasFechadas: DadosParaReaberturaMultiplaDeVendas[] = []

	//ordena vendas possivelmente fechadas por quantidade de itens da maior para a menor
	vendasFechadas.sort((vendaA, vendaB) => {
		const itensBipadosA = vendaA.itens.length
		const itensBipadosB = vendaB.itens.length

		return itensBipadosB - itensBipadosA
	})

	vendasFechadas = vendasFechadas
		.map((vendaPodeSerFechada) => {
			const vendaZerada = listaDeVendas.find(
				(vz) => vz.id === vendaPodeSerFechada.id,
			)

			if (vendaZerada) {
				vendaZerada.itens.forEach((itemZerado) => {
					const produtoLido = produtosLidos.find(
						(pl) => pl.produto.id === itemZerado.produto.id,
					)

					if (produtoLido) {
						const quantidadeDisponivel = Math.min(
							itemZerado.quantidade,
							produtoLido.quantidadeLida,
						)
						if (quantidadeDisponivel > 0) {
							itemZerado.quantidadeLida = quantidadeDisponivel
							produtoLido.quantidadeLida -= quantidadeDisponivel
						}
					}
				})
				return vendaZerada
			}

			return vendaPodeSerFechada
		})
		.filter(
			(vendaFechada) =>
				vendaFechada.itens
					.map((item) => item.quantidade - item.quantidadeLida)
					.reduce((total, valor) => (total += valor), 0) === 0,
		)

	let vendasNaoFechadas = listaDeVendas.filter((venda) =>
		venda.itens.some((item) => item.quantidade != item.quantidadeLida),
	)

	vendasNaoFechadas.sort((vendaA, vendaB) => {
		const itensBipadosA = vendaA.itens.length
		const itensBipadosB = vendaB.itens.length

		return itensBipadosA - itensBipadosB
	})

	//completa distribuição dos itens que ainda não foram distribuidos priorizando a vendas com menos itens
	vendasNaoFechadas = vendasNaoFechadas.map((vendaNaoFechada) => {
		vendaNaoFechada.itens.forEach((itemNaoFechado) => {
			const produtoLido = produtosLidos.find(
				(pl) => pl.produto.id === itemNaoFechado.produto.id,
			)
			const nenhumaOutraVendaTem =
				vendasFechadas.filter(
					(venda) => !venda.itens.some((item) => item.produto.id === itemNaoFechado.produto.id),
				).length === 1

			if (produtoLido) {
				const quantidadeDisponivel = Math.min(
					itemNaoFechado.quantidade,
					produtoLido.quantidadeLida,
				)

				if (quantidadeDisponivel > 0 && nenhumaOutraVendaTem) {
					itemNaoFechado.quantidadeLida = quantidadeDisponivel
					produtoLido.quantidadeLida -= quantidadeDisponivel
				}
			}
		})
		return vendaNaoFechada
	})

	vendasNaoFechadas = vendasNaoFechadas.map((vendaNaoFechada) => {
		vendaNaoFechada.itens.forEach((itemNaoFechado) => {
			const produtoLido = produtosLidos.find(
				(pl) => pl.produto.id === itemNaoFechado.produto.id,
			)
			const temProdutoComQuantidadeLida = vendasNaoFechadas.filter(
				venda => venda.id === vendaNaoFechada.id &&
					venda.itens.map(item => item.quantidadeLida).reduce((total, valor) => total += valor, 0) > 0,
			).length > 0

			if (produtoLido) {
				const quantidadeDisponivel = Math.min(
					itemNaoFechado.quantidade,
					produtoLido.quantidadeLida,
				)

				if (quantidadeDisponivel > 0 && temProdutoComQuantidadeLida) {
					itemNaoFechado.quantidadeLida = quantidadeDisponivel
					produtoLido.quantidadeLida -= quantidadeDisponivel
				}
			}
		})

		return vendaNaoFechada
	})

	vendasNaoFechadas = vendasNaoFechadas.map((vendaNaoFechada) => {
		vendaNaoFechada.itens.forEach((itemNaoFechado) => {
			const produtoLido = produtosLidos.find(
				(pl) => pl.produto.id === itemNaoFechado.produto.id,
			)

			const temProdutoComQuantidadeLida =
				vendasNaoFechadas.filter(
					(venda) =>
						venda.id === vendaNaoFechada.id &&
						venda.itens
							.map((item) => item.quantidadeLida)
							.reduce((total, valor) => (total += valor), 0) > 0,
				).length > 0

			if (produtoLido) {
				const quantidadeDisponivel = Math.min(
					itemNaoFechado.quantidade,
					produtoLido.quantidadeLida,
				)

				if (quantidadeDisponivel > 0 && temProdutoComQuantidadeLida) {
					itemNaoFechado.quantidadeLida = quantidadeDisponivel
					produtoLido.quantidadeLida -= quantidadeDisponivel
				}
			}
		})

		return vendaNaoFechada
	})

	vendasComOsItensLidos.sort((vendaA, vendaB) => {
		const itensBipadosA = vendaA.itens.length
		const itensBipadosB = vendaB.itens.length

		return itensBipadosA - itensBipadosB
	})

	vendasComOsItensLidos.forEach((vendaComItemLido) => {
		vendaComItemLido.itens.forEach((itemLido) => {
			const produtoLido = produtosLidos.find((pl) => pl.produto.id === itemLido.produto.id)

			if (produtoLido) {
				const quantidadeDisponivel = Math.min(
					itemLido.quantidade,
					produtoLido.quantidadeLida,
				)

				if (quantidadeDisponivel > 0) {
					itemLido.quantidadeLida = quantidadeDisponivel
					produtoLido.quantidadeLida -= quantidadeDisponivel
				}
			}
		})
	})

	vendasNaoFechadas = vendasNaoFechadas.map((vendaNaoFechada) => {
		vendaNaoFechada.itens.forEach((itemNaoFechado) => {
			const produtoLido = produtosLidos.find(
				(pl) => pl.produto.id === itemNaoFechado.produto.id,
			)

			if (produtoLido) {
				const quantidadeDisponivel = Math.min(
					itemNaoFechado.quantidade,
					produtoLido.quantidadeLida,
				)

				if (quantidadeDisponivel > 0 && (itemNaoFechado.quantidade - itemNaoFechado.quantidadeLida) > 0) {
					itemNaoFechado.quantidadeLida = quantidadeDisponivel
					produtoLido.quantidadeLida -= quantidadeDisponivel
				}
			}
		})

		return vendaNaoFechada
	})

	const vendasPreenchidas = [...vendasNaoFechadas, ...vendasFechadas]
	return vendasPreenchidas
}
