




































































































import {
	Vue,
	Component,
	PropSync,
	Prop,
	Ref,
	Watch,
} from 'vue-property-decorator'
import type { ItemDaVenda, Perfil, Venda } from '@/models'
import { Desconto } from '@/models'
import { formatarDecimal } from '@/shareds/formatadores'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import { FindPerfilUseCase, FindUsuarioUseCase } from '@/usecases'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { ParametrosParaAutenticacao } from '@/models/RegraDeNegocio'
import { PERFIL_LOGADO } from '@/common/constants'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { obterTotalDoItem } from '@/shareds/venda-shareds'

@Component({
	components: {
		CampoDecimal,
		CampoDePercentual,
	},
})
export default class BotaoDeDescontoDoItem extends Vue {
	@PropSync('value') desconto!: Desconto | null
	@Prop() venda!: Venda
	@Prop() item!: ItemDaVenda
	@Ref() botaoDeAcaoDeDesconto!: { $el: HTMLButtonElement }
	@Ref() campoDeValor!: CampoDecimal | CampoDePercentual
	@Ref() form!: HTMLFormElement
	@Prop({ type: Boolean }) disabled!: boolean
	@Ref() menuDeDesconto!: { $el: HTMLMenuElement }

	findUsuarioUseCase = new FindUsuarioUseCase()
	findPerfilUseCase = new FindPerfilUseCase()

	formValid = false
	CampoDePercentual = CampoDePercentual
	CampoDecimal = CampoDecimal

	formatarDecimal = formatarDecimal
	obterTotalDoItem = obterTotalDoItem

	mostraMenuDeDesconto = false

	descontoEmEdicao: Desconto | null = null

	get descontoEValido() {
		return () => {
			if (!this.descontoEmEdicao) return true
			if (
				!this.descontoEmEdicao.isPercentual &&
				this.descontoEmEdicao.valor > this.total - 0.01
			)
				return 'Valor maior que o total da venda'
			if (
				this.descontoEmEdicao.isPercentual &&
				this.descontoEmEdicao.valor > 99.99
			)
				return 'Valor não pode ser maior que 99.99%'
			return true
		}
	}

	get lojaDaVendaAtual(): string | undefined {
		return VendaModule.turnoDeVenda
			? VendaModule.vendaAtual?.pontoDeVenda?.loja.id || undefined
			: undefined
	}

	get total() {
		return obterTotalDoItem(this.item)
	}
	
	get componenteDeCampoDeValor() {
		return this.descontoEmEdicao && this.descontoEmEdicao.isPercentual
			? 'CampoDePercentual'
			: 'CampoDecimal'
	}

	setPercentual(valor: boolean) {
		if (!this.descontoEmEdicao) return
		this.descontoEmEdicao.isPercentual = valor
	}

	get podeConcederDesconto() {
		return UserLoginStore.permiteRegraDeNegocio('pode-conceder-desconto')
	}

	created() {
		document.addEventListener('keydown', this.atalhoDeDesconto)
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeDesconto)
	}

	atalhoDeDesconto(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 'd') {
			event.preventDefault()
			this.botaoDeAcaoDeDesconto.$el.click()
		}
	}

	removerDesconto() {
		this.item.desconto = {
			isPercentual: true,
			valor: 0,
		}
	}

	@Watch('descontoEmEdicao.isPercentual')
	onChangeIsPercentual() {
		if (!this.desconto) return
		setTimeout(() => {
			this.campoDeValor.focus()
		})
	}

	async concederDesconto() {
		this.descontoEmEdicao = {
			isPercentual: this.descontoEmEdicao ? this.descontoEmEdicao.isPercentual || false : false,
			valor: this.descontoEmEdicao && this.descontoEmEdicao.valor || 0,
		}

		const params: ParametrosParaAutenticacao = {
			lojaId: this.lojaDaVendaAtual,
			regra: 'pode-conceder-desconto',
			desconto: this.descontoEmEdicao,
			item: this.item,
		}

		try{
			if (!this.podeConcederDesconto) throw new Error('Usuário não tem permissão para conceder desconto')

			const perfil: Perfil = localStorage[PERFIL_LOGADO]
				? JSON.parse(localStorage[PERFIL_LOGADO])
				: null

			this.validarValorDescontoParaLiberar(perfil, params)
			this.confirmarDesconto()
		} catch(e) {
			AlertModule.setError(e)			
			if(!(await VendaModule.autenticarAcao(params))) return
			this.confirmarDesconto()
		}
		
	}

	validarValorDescontoParaLiberar(perfil: Perfil, params: ParametrosParaAutenticacao) {
		if(!this.item || !params.desconto || perfil.percentualMaximoDescontoPorItem === 0) return
		if(perfil.permissoes.includes('pode-conceder-desconto-por-item')) {
			if(this.item.possuiDescontoPorTabela) {
				const porcentagemDeDescontoDoItem = params.desconto.isPercentual
					? params.desconto.valor
					: (params.desconto.valor / this.item.preco) *100
				
				const percentualDeDescontoMaximo = Math.max(this.item.percentualDescontoTabelaPreco, perfil.percentualMaximoDescontoPorItem)

				if(porcentagemDeDescontoDoItem > percentualDeDescontoMaximo && this.venda.pagamentos.length < 1) {
					throw new Error(this.item.percentualDescontoTabelaPreco > perfil.percentualMaximoDescontoPorItem 
						? `O desconto aplicado neste item (${porcentagemDeDescontoDoItem.toFixed(2)}%) 
							excede o limite máximo permitido pela tabela de preço (${this.item.percentualDescontoTabelaPreco.toFixed(2)}%).`
						: `Este item tem um desconto de ${porcentagemDeDescontoDoItem.toFixed(2)}%, mas você só pode conceder até
							${perfil.percentualMaximoDescontoPorItem}% de desconto por item. Ajuste o valor do desconto para não exceder o limite permitido.`)
				}
			} else {
				const valorDeDescontoDoItem = this.item.possuiDescontoPorTabela ? 0 : params.desconto.valor
				const porcentagemDeDescontoDoItem = params.desconto.isPercentual
					? valorDeDescontoDoItem
					: (params.desconto.valor / this.item.preco) *100

				if(porcentagemDeDescontoDoItem > perfil.percentualMaximoDescontoPorItem && this.venda.pagamentos.length < 1) {
					throw new Error(`Este item tem um desconto de ${porcentagemDeDescontoDoItem.toFixed(2)}%, mas você só pode conceder até
					${perfil.percentualMaximoDescontoPorItem.toFixed(2)}% de desconto por item. Ajuste o valor do desconto para não exceder o limite permitido.`)
				}
			}
		} else {
			throw new Error('Usuário não tem permissão para conceder desconto por item')
		}
	}

	async acaoDeDesconto() {
		this.mostraMenuDeDesconto = !this.mostraMenuDeDesconto
		this.descontoEmEdicao = this.desconto
			? { ...this.desconto }
			: {
				isPercentual: true,
				valor: 0,
			}
		if (!this.mostraMenuDeDesconto) return
		setTimeout(() => {
			this.campoDeValor.focus()
		}, 100)
	}

	async confirmarDesconto() {
		if (!this.form.validate()) return
		this.$emit('input', this.descontoEmEdicao)
		this.mostraMenuDeDesconto = false
		this.botaoDeAcaoDeDesconto.$el.focus()
	}
}
