var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"position":"relative"},attrs:{"fullscreen":"","persistent":"","scrollable":"","no-click-animation":"","light":""},model:{value:(_vm.syncMostra),callback:function ($$v) {_vm.syncMostra=$$v},expression:"syncMostra"}},[_c('v-card',{staticClass:"mt-2",attrs:{"dark":""}},[_c('v-card-title',[_c('h3',[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.$emit('voltar')}}},[_vm._v("mdi-arrow-left")])],1),_vm._v(" Busca inteligente de "+_vm._s(_vm.retornoMultiploDeDemonstracao ? "demonstração" : "consignado")+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('BuscaDeProdutoDropdown',{ref:"campoDeBuscaDeProduto",attrs:{"label":"Produto","return-object":"","requerFilho":"","filled":"","clearable":""},model:{value:(_vm.produto),callback:function ($$v) {_vm.produto=$$v},expression:"produto"}})],1),_c('v-expansion-panels',{attrs:{"expand":"","multiple":""},model:{value:(_vm.painelAberto),callback:function ($$v) {_vm.painelAberto=$$v},expression:"painelAberto"}},_vm._l((_vm.vendaFormatadas),function(venda,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{style:({ backgroundColor: _vm.isVendaCompletamentePreenchida(venda) ? 'rgb(76, 175, 80)': '' })},[_vm._v(" "+_vm._s(venda.identificador)+" ")]),_c('v-expansion-panel-content',{style:({ backgroundColor: _vm.isVendaCompletamentePreenchida(venda) ? 'rgb(76, 175, 80)': '' })},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":venda.itens},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'text-decoration-line-through': item.quantidadeLida === item.quantidade }},[_vm._v(" "+_vm._s(item.nome)+" ")])]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'text-decoration-line-through': item.quantidadeLida === item.quantidade }},[_vm._v(" "+_vm._s(item.produto.sku)+" ")])]}},{key:"item.eans",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'text-decoration-line-through': item.quantidadeLida === item.quantidade }},[_vm._v(" "+_vm._s(item.produto.eans[0])+" ")])]}},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'text-decoration-line-through': item.quantidadeLida === item.quantidade }},[_vm._v(" "+_vm._s(item.quantidade)+" ")])]}},{key:"item.quantidadeLida",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'text-decoration-line-through': item.quantidadeLida === item.quantidade }},[_vm._v(" "+_vm._s(item.quantidadeLida)+" ")])]}},{key:"item.preco",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'text-decoration-line-through': item.quantidadeLida === item.quantidade }},[_vm._v(" "+_vm._s(_vm.formatarMoeda(_vm.obterTotalDoItem(item)))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(((item.quantidade - item.quantidadeLida || 0) > 0) ||
									(item.quantidadeLida > 0 && item.quantidadeLida < item.quantidade))?_c('v-row',[((item.quantidade - item.quantidadeLida || 0) > 0)?_c('v-switch',{attrs:{"label":"Vender","color":"primary"},on:{"change":function($event){return _vm.alterouSwitch(venda, item)}},model:{value:(item.isCompra),callback:function ($$v) {_vm.$set(item, "isCompra", $$v)},expression:"item.isCompra"}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex justify-end"},on),[(item.quantidadeLida > 0 && item.quantidadeLida < item.quantidade)?_c('v-icon',{staticClass:"ml-2",attrs:{"medium":"","color":"yellow"}},[_vm._v("mdi-alert-outline")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" Devolução parcial ")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex justify-end"},on),[(item.quantidadeLida === item.quantidade)?_c('v-icon',{staticClass:"acao-coluna",attrs:{"medium":"","color":"green"}},[_vm._v("mdi-arrow-right-bottom-bold")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" Devolução completa ")])])]}}],null,true)})],1)],1)}),1)],1),_c('v-btn',{staticStyle:{"bottom":"36px","right":"24px"},attrs:{"fab":"","dark":"","fixed":"","color":"primary","loading":_vm.retornandoDemonstracoes,"disabled":!_vm.podeConcluirProcesso || _vm.retornandoDemonstracoes},on:{"click":function($event){return _vm.finalizarProcessoDeRetornoMultiplo()}}},[_c('v-icon',[_vm._v(" mdi-check ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }