import { ItemDaVenda, ItemNotaFiscal, NotaFiscalCompleta, TipoDeTransacaoDeVenda, TurnoDeVenda, Venda } from '@/models'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { criarDevolucao, obterValorDoFreteRateado, obterValorDoItemDevolucao } from '@/shareds/venda-shareds'
import AlterarPrecosDaVendaPorCliente from './AlterarPrecosDaVendaPorCliente'
import { Inject } from 'inversify-props'
import type { ModalidadeDeVendaServiceAdapter, VendaServiceAdapter } from '.'
import AlterarPrecosDaNotaPorCliente from './AlterarPrecosDaNotaPorCliente'

type UseCaseParams = {
	vendaModule?: typeof VendaModule
	itensADevolver: ItemDaVenda[]
	vendaOrigem: Venda
	vendaOriginal: Venda
	turnoDeVenda: TurnoDeVenda | null
	cpfOuCnpjDoCliente: string | null
}

type UseCaseParamsSemTurno = {
	vendaModule?: typeof VendaModule
	itensADevolver: ItemDaVenda[]
	vendaOrigem: Venda
	vendaOriginal: Venda
	tipoDeTransacao: TipoDeTransacaoDeVenda
}

type UseCaseParamsDevolucaoSaida = {
	vendaModule?: typeof VendaModule
	itensADevolver: ItemNotaFiscal[]
	notaFiscalOrigem: NotaFiscalCompleta
}

export default class IniciarUmaDevolucao {

	@Inject("VendaServiceAdapter")
	private vendaService!: VendaServiceAdapter

	@Inject("ModalidadeDeVendaServiceAdapter")
	private modalidadeService!: ModalidadeDeVendaServiceAdapter

	devolver = async ({
		vendaModule = VendaModule,
		itensADevolver,
		vendaOrigem,
		vendaOriginal,
		turnoDeVenda,
		cpfOuCnpjDoCliente,
	}: UseCaseParams): Promise<void> => {
		if (!itensADevolver.length) throw new Error('Nenhum item selecionado')
		const itensDaDevolucao = itensADevolver.map(item => ({
			...item,
			desconto: {
				isPercentual: false,
				valor: 0,
			},
			preco: obterValorDoItemDevolucao(item, this.obterQuantidadeOriginalDoItem(vendaOriginal, item.id)),
			idItemOrigem: item.idItemOrigem || item.id,
			valorFreteRateado: obterValorDoFreteRateado(item, this.obterQuantidadeOriginalDoItem(vendaOriginal, item.id)),
		}))

		let parametrosFiscaisTratado
		if (turnoDeVenda) {
			parametrosFiscaisTratado = turnoDeVenda.pontoDeVenda.parametrosFiscais
		}

		const lojaAtual = turnoDeVenda?.pontoDeVenda.loja.id
		const lojaDaVendaOrigem = vendaOrigem.pontoDeVenda?.loja.id
		const lojaDaVendaOriginal = vendaOriginal.pontoDeVenda?.loja.id

		if (lojaAtual !== lojaDaVendaOrigem) {
			const vendaOrigemSemVendedor = {
				...vendaOrigem,
				vendedor: null,
			};

			for (let i = 0; i < vendaOrigem.itens.length; i++) {
				vendaOrigem.itens[i].vendedor = null;
			}
			vendaOrigem = vendaOrigemSemVendedor;

			for (let i = 0; i < itensDaDevolucao.length; i++) {
				itensDaDevolucao[i].vendedor = null
			}
		}

		if (lojaAtual !== lojaDaVendaOriginal) {
			const vendaOriginalSemVendedor = {
				...vendaOriginal,
				vendedor: null,
			};

			for (let i = 0; i < vendaOriginal.itens.length; i++) {
				vendaOriginal.itens[i].vendedor = null;
			}
			vendaOriginal = vendaOriginalSemVendedor;

			for (let i = 0; i < itensDaDevolucao.length; i++) {
				itensDaDevolucao[i].vendedor = null
			}
		}

		const totalValorFrete = itensDaDevolucao.map(item => item.valorFreteRateado).reduce((soma, valor) => soma + valor)

		const devolucao = criarDevolucao({
			itens: itensDaDevolucao,
			vendaOrigem,
			vendaOriginal,
			...parametrosFiscaisTratado,
			totalValorFrete,
		})

		if (turnoDeVenda) {
			devolucao.identificador = await this.vendaService.gerarIdentificadorDeVenda(turnoDeVenda.pontoDeVenda.id)
			devolucao.loja = turnoDeVenda.pontoDeVenda.loja.id
		}

		if (cpfOuCnpjDoCliente !== null) {
			devolucao.cpfDoCliente = cpfOuCnpjDoCliente
		}

		if (vendaModule.lojaDaVenda && vendaOrigem.pontoDeVenda && vendaModule.lojaDaVenda.id !== vendaOrigem.pontoDeVenda.loja.id) {
			devolucao.tipoDeTransacao = 'Outros'
		} else {
			devolucao.tipoDeTransacao = 'Devolução'
		}

		const modalidadesDisponiveis = await this.modalidadeService.find(vendaModule.lojaDaVenda?.id)
		const modalidadeDevolucao = modalidadesDisponiveis.filter(modalidade => modalidade.origem === 'Devolução')

		if (modalidadeDevolucao.length > 0) {
			devolucao.modalidadeDeVenda = modalidadeDevolucao[0]
		}

		vendaModule.setVendaAtual(devolucao)
		vendaModule.setInformacoesDoCliente(null)
		await AlterarPrecosDaVendaPorCliente({
			cliente: devolucao.cpfDoCliente,
			forcarReconsulta: true,
			vendaModule,
			validarLimiteDeProduto: false,
		})
		if (!devolucao.cliente) vendaModule.setInformacoesDoCliente(null)
		vendaModule.setRecarregaVenda(true)
	}

	devolverVendaSemTurno = async ({
		vendaModule = VendaModule,
		itensADevolver,
		vendaOrigem,
		vendaOriginal,
		tipoDeTransacao,
	}: UseCaseParamsSemTurno): Promise<void> => {
		if (!itensADevolver.length) throw new Error('Nenhum item selecionado')

		const itensDaDevolucao = itensADevolver.map(item => ({
			...item,
			desconto: {
				isPercentual: false,
				valor: 0,
			},
			preco: obterValorDoItemDevolucao(item, this.obterQuantidadeOriginalDoItem(vendaOrigem, item.id)),
			idItemOrigem: item.idItemOrigem || item.id,
		}))

		let parametrosFiscaisTratado

		const devolucao = criarDevolucao({
			itens: itensDaDevolucao,
			vendaOrigem,
			vendaOriginal,
			tipoDeTransacao,
			...parametrosFiscaisTratado,
		})

		if (vendaModule.lojaDaEntrada?.id) {
			devolucao.identificador = await this.vendaService.gerarIdentificadorDeVendaSemTurno(vendaModule.lojaDaEntrada?.id)
			devolucao.loja = vendaModule.lojaDaEntrada.id
		}

		vendaModule.setEmissaoEntradaAtual(devolucao)
		vendaModule.setInformacoesDoClienteDaNota(null)
		await AlterarPrecosDaNotaPorCliente({
			cliente: devolucao.cpfDoCliente,
			forcarReconsulta: true,
			vendaModule,
			validarLimiteDeProduto: false,
		})
		if (!devolucao.cliente) vendaModule.setInformacoesDoClienteDaNota(null)
		vendaModule.setRecarregaNota(true)
	}

	devolverNotaSaida = async ({
		vendaModule = VendaModule,
		itensADevolver,
		notaFiscalOrigem,
	}: UseCaseParamsDevolucaoSaida): Promise<void> => {
		if (!itensADevolver.length) throw new Error('Nenhum item selecionado')
		if (!vendaModule.emissaoEntradaAtual) throw new Error('Falha ao iniciar devolucao')

		vendaModule.emissaoEntradaAtual.itens =
			itensADevolver.map(item => {
				return {
					id: '',
					produto: item.produto,
					desconto: { isPercentual: true, valor: 0 },
					preco: Number(item.vunCom) || 0,
					descontoVarejo: null,
					quantidade: item.quantidadeSaldo,
					quantidadeLida: 0,
					precoDeCusto: 0,
					idConjunto: null,
					percentualCashback: 0,
					diasParaEfetivarCashback: 0,
					isBrinde: false,
					idItemNotaOrigem: item.id,
					chaveNotaOrigem: notaFiscalOrigem.chNFe,
					diasParaExpirarCashback: 0,
					possuiDescontoPorTabela: false,
					valorDescontoRateadoDoItem: 0,
					itemDeDevolucao: false,
					isTotalmenteTrocado: false,
					motivoCancelamento: null,
					isCompra: false,
					idItemOrigem: '',
					isDaVendaReaberta: false,
					vendedor: null,
					autenticadoParaLiberarProdutoSemEstoque: false,
					vendaOrigemDoItem: null,
					valorFreteRateado: 0,
					isItemReferenteATroca: false,
					isDeOutraLoja: false,
					percentualDescontoTabelaPreco: 0,
				} as ItemDaVenda
			})
		if (vendaModule.lojaDaEntrada?.id) {
			vendaModule.emissaoEntradaAtual.identificador = await this.vendaService.gerarIdentificadorDeVendaSemTurno(vendaModule.lojaDaEntrada?.id)
			vendaModule.emissaoEntradaAtual.loja = vendaModule.lojaDaEntrada.id
		}
		vendaModule.setRecarregaNota(true)
	}

	obterQuantidadeOriginalDoItem(vendaOrigem: Venda, id: string) {
		const item = vendaOrigem?.itens.find(item => item.id === id)
		if (!item) return 0
		return item.quantidade
	}
}