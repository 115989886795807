

























































































































import {
	Vue,
	Component,
	PropSync,
	Prop,
	Ref,
	Watch,
} from 'vue-property-decorator'
import type { Perfil, Venda } from '@/models'
import { Desconto } from '@/models'
import { formatarMoeda, formatarDecimal } from '@/shareds/formatadores'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import {
	obterDescontoDaVenda,
	obterTotalDaVenda,
} from '@/shareds/venda-shareds'
import { FindPerfilUseCase, FindUsuarioUseCase } from '@/usecases'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { ParametrosParaAutenticacao } from '@/models/RegraDeNegocio'
import { PERFIL_LOGADO } from '@/common/constants'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		CampoDecimal,
		CampoDePercentual,
	},
})
export default class BotaoDeDesconto extends Vue {
	@PropSync('value') desconto!: Desconto | null
	@Prop() venda!: Venda
	@Ref() botaoDeAcaoDeDesconto!: { $el: HTMLButtonElement }
	@Ref() campoDeValor!: CampoDecimal | CampoDePercentual
	@Ref() form!: HTMLFormElement
	@Prop({ type: Boolean }) disabled!: boolean
	@Ref() menuDeDesconto!: { $el: HTMLMenuElement }

	findUsuarioUseCase = new FindUsuarioUseCase()
	findPerfilUseCase = new FindPerfilUseCase()

	formValid = false
	CampoDePercentual = CampoDePercentual
	CampoDecimal = CampoDecimal

	formatarDecimal = formatarDecimal
	mostraMenuDeDesconto = false

	descontoEmEdicao: Desconto | null = null

	get descontoEValido() {
		return () => {
			if (!this.descontoEmEdicao) return true
			if (
				!this.descontoEmEdicao.isPercentual &&
				this.descontoEmEdicao.valor > this.total - 0.01
			)
				return 'Valor maior que o total da venda'
			if (
				this.descontoEmEdicao.isPercentual &&
				this.descontoEmEdicao.valor > 99.99
			)
				return 'Valor não pode ser maior que 99.99%'
			return true
		}
	}

	get lojaDaVendaAtual(): string | undefined {
		return VendaModule.turnoDeVenda
			? VendaModule.vendaAtual?.pontoDeVenda?.loja.id || undefined
			: undefined
	}

	get total() {
		return obterTotalDaVenda(this.venda)
	}

	get componenteDeCampoDeValor() {
		return this.descontoEmEdicao && this.descontoEmEdicao.isPercentual
			? 'CampoDePercentual'
			: 'CampoDecimal'
	}

	setPercentual(valor: boolean) {
		if (!this.descontoEmEdicao) return
		this.descontoEmEdicao.isPercentual = valor
	}

	get valorDoDesconto() {
		if (!this.desconto) return 0
		return obterDescontoDaVenda({
			...this.venda,
			desconto: this.desconto,
		})
	}

	get valorDoDescontoFormatado() {
		return formatarMoeda(this.valorDoDesconto)
	}

	get podeConcederDesconto() {
		return UserLoginStore.permiteRegraDeNegocio('pode-conceder-desconto')
	}

	created() {
		document.addEventListener('keydown', this.atalhoDeDesconto)
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoDeDesconto)
	}

	atalhoDeDesconto(event: KeyboardEvent) {
		if (event.altKey && event.key.toLowerCase() === 'd') {
			event.preventDefault()
			this.botaoDeAcaoDeDesconto.$el.click()
		}
	}

	removerDesconto() {
		this.venda.desconto = null
		if(this.venda.cashbackAplicado) this.venda.cashbackAplicado = false
	}

	@Watch('descontoEmEdicao.isPercentual')
	onChangeIsPercentual(isPercentual: boolean) {
		if (!this.desconto) return
		this.desconto.valor = isPercentual
			? this.desconto.valor / 100
			: this.desconto.valor
		setTimeout(() => {
			this.campoDeValor.focus()
		})
	}

	async concederDesconto() {
		const params: ParametrosParaAutenticacao = {
			lojaId: this.lojaDaVendaAtual,
			regra: 'pode-conceder-desconto',
			desconto: this.descontoEmEdicao,
		}

		try{
			const perfil: Perfil = localStorage[PERFIL_LOGADO]
				? JSON.parse(localStorage[PERFIL_LOGADO])
				: null
			const venda = VendaModule.vendaAtual

			this.validarValorDescontoParaLiberar(perfil, venda, params)
			this.confirmarDesconto()
		} catch(e) {
			AlertModule.setError(e)			
			if(!(await VendaModule.autenticarAcao(params))) return
			this.confirmarDesconto()
		}
		
	}

	validarValorDescontoParaLiberar(perfil: Perfil, venda: Venda | null, params: ParametrosParaAutenticacao) {
		if (!this.podeConcederDesconto) throw new Error('Usuário não tem permissão para conceder desconto')
		if(perfil.percentualMaximoDesconto === 0) return

		if (venda && params.desconto && params.desconto.valor > 0) {
			let valorTotalDeDescontoDosItens = 0;
			let valorBrutoDaVenda = 0;

			for(let i=0; venda.itens.length > i; i++) {
				const item = venda.itens[i]
				
				if(!item.possuiDescontoPorTabela && item.isCompra && params.desconto.valor > 0) {
					if(item.desconto.isPercentual) {
						const retiraValorDaPorcentagem = item.preco * (item.desconto.valor / 100);
						valorTotalDeDescontoDosItens = valorTotalDeDescontoDosItens + retiraValorDaPorcentagem;
					} else {
						valorTotalDeDescontoDosItens = valorTotalDeDescontoDosItens + item.desconto.valor;
					}
				}
				if(item.possuiDescontoPorTabela) {
					const descontoDaTabelaDePreco = (venda.itens[i].preco * (venda.itens[i].desconto.valor / 100))
					valorBrutoDaVenda = valorBrutoDaVenda + (venda.itens[i].preco - descontoDaTabelaDePreco)
				} else {
					valorBrutoDaVenda = valorBrutoDaVenda + (venda.itens[i].preco)
				}
			}

			const porcentagemDeDescontoDaCapa = params.desconto.isPercentual
				? params.desconto.valor
				: (params.desconto.valor / valorBrutoDaVenda) *100

			if (porcentagemDeDescontoDaCapa > perfil.percentualMaximoDesconto && venda.pagamentos.length < 1) {
				throw new Error(`O desconto aplicado é de ${porcentagemDeDescontoDaCapa.toFixed(2)}%, 
					mas o limite permitido para a capa da venda é de ${perfil.percentualMaximoDesconto.toFixed(2)}%`)
			} 
		}
	}

	async acaoDeDesconto() {
		this.mostraMenuDeDesconto = !this.mostraMenuDeDesconto
		this.descontoEmEdicao = this.desconto
			? { ...this.desconto }
			: {
				isPercentual: true,
				valor: 0,
			}
		if (!this.mostraMenuDeDesconto) return
		setTimeout(() => {
			this.campoDeValor.focus()
		}, 100)
	}

	async confirmarDesconto() {
		if (!this.form.validate()) return
		this.$emit('input', this.descontoEmEdicao)
		this.mostraMenuDeDesconto = false
		this.botaoDeAcaoDeDesconto.$el.focus()
	}
}
